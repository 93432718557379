import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TopRanksContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		padding: 16px 0px;
		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 195px;
			z-index: 1;
		}
		.pics {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			justify-content: space-between;
			align-items: center;
			padding: 0 16px;
			font-size: 14px;
			font-weight: 500;
			line-height: 17px;
			.number {
				font-size: 12px;
				font-weight: 400;
				line-height: 14px;
			}
			.name {
				max-width: 82px;
				word-wrap: break-word;
				overflow-wrap: break-word;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			div {
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 3px;
				justify-content: center;
			}
			.one {
				grid-column: 2;
				img {
					width: 100px;
					height: 99px;
				}
			}
			.three {
				grid-column: 1;
			}
			.two {
				grid-column: 3;
			}
			.three,
			.two {
				grid-row: 2;
				margin-top: -50px;
				img {
					width: 61px;
					height: 61px;
				}
			}
		}

		@media (max-width: 344px) {
			.pics {
				.one {
					margin-left: -8px;
				}
			}
		}
	`,
);
