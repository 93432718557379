import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NotifModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		text-align: center;
		.header_img {
			width: 186px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -180px;
		}
		h2 {
			margin: 82px 0 20px;
			font-weight: 600;
			font-size: 24px;
			line-height: 20px;
			color: ${theme.colors.text.primary};
			padding: 16px 24px 0;
			text-align: center;
		}
		p {
			color: ${theme.colors.text.primary};
			padding: 0 24px;
			margin-bottom: 16px;
		}

		.friends_container {
			color: ${theme.colors.text.primary};
			padding: 0 24px;
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-bottom: 16px;

			.title {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
			}

			.value {
				color: ${theme.colors.text.primary};
				font-weight: 600;
				font-size: 14px;
			}
		}

		.reward_container {
 			padding: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			margin-bottom: 32px;

			img {
				width: 28px;
			}

			.title {
				color: ${theme.colors.text.primary};
				font-weight: 400;
				font-size: 24px;
				line-height: 20px;
				margin-top: 1px;
			}
		}

		button {
			margin: 0 24px 24px;
			width: calc(100% - 48px);
		}
	`,
);
