import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OverviewContainer = styled.section(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		user-select: none;
		align-items: center;
		padding: 30px;
		/* background: linear-gradient(180deg, rgba(36, 110, 156, 1) 0%, rgba(48, 118, 163, 1) 100%); */
		position: relative;
		.background {
			position: absolute;
			width: 86%;
			height: 69%;
			overflow: hidden;
			z-index: 1;
			top: 16px;
			left: 0;
			right: 0;
			margin: 0 auto;
			border-radius: 12px;
 
 
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.card_item_container {
			background: #2387c5;
			position: relative;
			z-index: 2;
			width: 50%;
			text-align: left;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap:3px;
			&.left{
				border-left: 1px solid #86BDDF;
			}
			.card_item {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 3px;
				color: ${theme.colors.text.primary};
				.pic {
					width: 38px;
					height: 38px;
					&.right{
						width: 42px;
						height: 42px;
					}
				}
				.text_container {
					display: flex;
					flex-direction: column;
					gap: 2px;
					align-items: left;
					justify-content: center;
					.title {
						font-size: 15px;
						font-weight: 400;
						line-height: 18.57px;
					}
					.value {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.86px;

					}
				}
			}
		}
	`,
);
