import { useEffect, useState } from 'react';
import { ExchangeContainer } from './css/exchange.style';
import { Binance, Bybit, KuCoin, Okx } from './img';
import { ArrowRightOutline, TickBold } from 'shared/components/icons';
import { GetExchange, POSTConnectExchange } from 'api/v1/exchange';
import DotLoader from 'shared/components/dot-loader';
import { useNavigate } from 'react-router-dom';
const Exchange = () => {
	const [chacked, setChecked] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const data = [
		{
			icon: <Binance />,
			text: 'Binance',
		},
		{
			icon: <KuCoin />,
			text: 'Kucoin',
		},
		{
			icon: <Bybit />,
			text: 'Bybit',
		},
		{
			icon: <Okx />,
			text: 'OKX',
		},
	];
	const handleClick = async (item: { text: string }) => {
		setLoading(true);
		POSTConnectExchange(item.text)
			.then(() => {
				setChecked(item.text);
				setLoading(false);
				navigate('/');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		GetExchange().then(res => {
			setChecked(res);
		});
	}, []);

	return (
		<ExchangeContainer>
			<h1>Choose Exchange</h1>

			{data.map((item, index) => {
				return (
					<div className='border' key={`index-${index}`}>
						<div className='list_item' onClick={() => handleClick(item)}>
							<div className='content'>
								<div className='icon'>{item.icon}</div>
								<div className='text'>{item.text}</div>
							</div>
							<div className='action'>
								{loading ? (
									<DotLoader />
								) : item.text === chacked ? (
									<TickBold className='green' />
								) : (
									<ArrowRightOutline className='arrow' />
								)}
							</div>
						</div>
					</div>
				);
			})}
		</ExchangeContainer>
	);
};
export default Exchange;
