import { CSSProperties, FC, ReactNode } from 'react';
import { DotLoaderContainer } from './dot-loader.style.ts';

const DotLoader: FC<{
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
}> = ({ className, style }) => {
	return (
		<DotLoaderContainer className={`${className ? className : ''}`} style={style}>
			<div></div>
			<div></div>
			<div></div>
		</DotLoaderContainer>
	);
};

export default DotLoader;
