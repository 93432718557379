import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BoostContainer = styled.div(
	({ theme }) => css`
		padding: 24px 24px 160px;
		background: linear-gradient(180deg, #2372A2 10%, rgba(127,178,211,1) 50%, rgba(34,113,161,1) 100%);

		.top_light {
			width: 187px;
			height: 187px;
			background: #91cddb99;
			filter: blur(100px);
			opacity: 0.5;
			position: absolute;
			left: 0;
			right: 0;
			top: -120px;
			margin: auto;
		}

		.note_container {
			border: 1px dashed #284c69;
			padding: 16px;
			color: ${theme.colors.text.secondary};
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			border-radius: 8px;

			strong {
				color: ${theme.colors.text.primary};
				font-weight: 600;
			}
		}
	`,
);
