import { useEffect, useState } from 'react';
import { LotteryList, TicketCards, Timer } from './components';
import { LotteryContainer } from './css';
// import { WinnerVector } from './img';
import { GETLotteryAPI, ILotteryResponse } from 'api/v2/lottery';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';

const Lottery = () => {
	const [data, setData] = useState<ILotteryResponse | null>(null);
	const [hasBuyed, setHasBuyed] = useState(false);
	const [codes, setCodes] = useState<string[]>([]);

	useEffect(() => {
		GETLotteryAPI().then(res => {
			setData(res);
			setHasBuyed(res.ticket.hasBuyed);
			setCodes(res.ticket.codes);
		});
	}, []);

	return (
		<LotteryContainer>
			<Helmet>
				<title>PinEye | Lottery</title>
			</Helmet>
			{!data ? (
				<Loader />
			) : (
				<div className='main_container'>
					<h1>Become a Millionaire!</h1>

					<div className='note'>
						🎟️ Buy daily tickets for a chance to win 10M coins!
						<br /> 💰 A random winner is selected every day at 00:00 UTC. Will you be the next lucky one?
					</div>
					<Timer date={data.lotteryRule.endOfLottery * 1000} />
					{hasBuyed === true ? (
						<LotteryList codes={codes} />
					) : (
						<TicketCards
							setCodes={(codes: string[]) => setCodes(codes)}
							setHasBuyed={(hasBuyed: boolean) => setHasBuyed(hasBuyed)}
							data={data}
						/>
					)}
				</div>
			)}
		</LotteryContainer>
	);
};

export default Lottery;
