import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DailyModalContainer = styled.section(
	({ theme }) => css`
		padding: 0 24px 24px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		gap: 16px;
 		.calender {
			width: 188px;
			height: 180px;
			position: absolute;
			top: -160px;
			left: 0;
			right: 0;
			margin: auto;
		}
		.content{
			text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		gap: 16px;
		h3 {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
		}
		p {
			color: ${theme.colors.text.primary};
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			padding-bottom: 8x;
		}

		.reward_container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-rows: 1fr ;
			gap: 8px;
			margin-bottom: 32px;

			.reward_item {
				border-radius: 10px;
				padding: 1px;
				.border {
					box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
					display: flex;
					flex-direction: column;
					position: relative;
					overflow: hidden;
					background: linear-gradient(189.11deg, #3b96ce 14.93%, #206b99 95.58%);
					padding: 8px;
					border-radius: 9px;
					align-items: center;
					justify-content: center;
					position: relative;
					gap: 2px;
					z-index: 1;
					&.deactive {
						background: #04578996;
					}
					.tick {
						/* width: 70PX; */
						height: 90px;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: auto;
						z-index: 3;
					}
					.no_ticket {
						display: none;
					}
					.dollor {
						width: 37px;
						height: 37px;
					}
					.title {
						font-weight: 400;
						font-size: 12px;
						line-height: 20px;
					}
					.reward {
						font-weight: 600;
						font-size: 12px;
						line-height: 20px;
					}
				}
			}
		}

		button {
			width: 100%;
		}
	}
		@media (max-height: 667px) {
			padding: 0 24px 10px;
			gap: 16px;
			.calender {
				width: 130px;
				height: 130px;
				top: -120px;
			}
			.content{
				height: 350px;
				overflow: auto;
				display: flex;
				flex-direction: column;
				gap: 16px;
				h3 {
				line-height: 20px;
				font-size: 20px;
				}
				p {
				line-height: 15px;
				font-size: 14px:
				}
				.reward_container {
				gap: 4px;
				margin-bottom: 0;
					.reward_item {
					.border {
						padding: 5px;
						.dollor {
							width: 25px;
							height: 25px;
						}
						.title,
						.reward {
							font-size: 11px;
							}
						}
					}
				}
			}
		}
	`,
);
