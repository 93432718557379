import { FC, useState } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { DailyModalContainer } from '../css';
import { IDailyRewardResponse, POSTClaimDailyRewards } from 'api/v1/daily-reward';
import { useDispatch } from 'react-redux';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import toast from 'react-hot-toast';
import Icon from 'shared/components/icon';

const DailyModal: FC<{
	status: boolean;
	title: string;
	data: IDailyRewardResponse;
	setStatus: (status: boolean) => void;
}> = ({ status, title, data, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useDispatch();

	const onClaimClickHandler = () => {
		if (data.canClaim && !isLoading) {
			setIsLoading(true);
			POSTClaimDailyRewards()
				.then(res => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					toast.success('daily reward claimed successfully');
					dispatch(changeBalance(res));
					dispatch(changeReplay());
					onClose();
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<DailyModalContainer>
				<Icon name='colored_calendar' className='calender' />
				<div className='content'>
					<h3>{title}</h3>
					<p>Accrue coins for logging into the game daily with out skipping!</p>
					<div className='reward_container'>
						{data.rewards.map((item, index) => (
							<div className={`reward_item  `} key={`reward_item_daily_${index}`}>
								<div className={`border ${item.isClaimed ? 'deactive' : ''}`}>
									<Icon name='dollor_with_border' className='dollor' />
									<Icon name='tick' className={item.isClaimed ? 'tick' : 'no_ticket'} />
									<div className='title'>{item.title}</div>
									<div className='reward'>{item.score}</div>
								</div>
							</div>
						))}
					</div>
					<Button variant='primary' onClick={onClaimClickHandler} isLoading={isLoading} disabled={!data.canClaim}>
						{data.canClaim ? 'Claim' : 'Come back tomorrow'}
					</Button>
				</div>
			</DailyModalContainer>
		</Modal>
	);
};

export default DailyModal;
