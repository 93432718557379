import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { envResolver } from 'shared/configs/env-resolver';
import 'shared/css/reset.css';
import store from 'store/index';
import App from './app';

Sentry.init({
	dsn: 'https://7292d24d7b141c69ece3a2d702cccd90@o4507630416297984.ingest.de.sentry.io/4507630418591824',
	...(envResolver.MODE === 'PRODUCTION' && {
		release: 'pineye-bot@' + envResolver.VERSION,
	}),
	// tunnel: 'https://pineye.io/tunnel',
	beforeSend(event) {
		if (event.exception && event.exception.values) {
			for (const item of event.exception.values) {
				if (item.value?.includes('Request') && item.value.includes('status code')) {
					return null;
				}
			}
		}
		return event;
	},
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.BrowserTracing({ tracingOrigins: ['*'], traceXHR: false, traceFetch: false }),
		new Sentry.Replay(),
		new Sentry.Breadcrumbs({ console: false, xhr: false, fetch: false }),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['localhost', /^/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<App />
	</Provider>,
);
