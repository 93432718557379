import { ArrowRightOutline } from 'shared/components/icons';
import { IncomeStatsContainer } from '../css';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import numberUtils from 'shared/utils/number';
const IncomeStats: FC<{ friendsCount?: number; friendsBalance?: number;}> =
 ({ friendsCount, friendsBalance }) => {
	 
	return (
		<IncomeStatsContainer>
			<div className='rewards_container'>
				<div className='reward_item'>
					<div className='main_div'>
						<img src='/img/dollar.webp' width={48} height={48} alt='pineye-coin' />
					<div className='value'>{friendsBalance ? numberUtils.formatPriceWithDecimals(friendsBalance) : 0}</div>
					<div className='title'>Your friends earning</div>
					</div>
				</div>
				<div className='reward_item '>
				<div className='main_div friends'>
					<div className='title'>Friends</div>
					<div className='value '>{friendsCount ? numberUtils.formatPriceWithDecimals(friendsCount) : 0}</div>
				</div>
				</div>
			</div>
			<div className='box_container'>
				<img src='/img/friends/gift.webp' alt='' />
				<h2>Extra 30% <span>Earnings on Your Friends</span></h2>
				<Link to={'/reward-history'} className='link'>
				<div className='link_text'>
					View all <ArrowRightOutline />
					</div>
				</Link>
			</div>
		</IncomeStatsContainer>
	);
};

export default IncomeStats;
