import requestHandler from 'shared/utils/request-handler';
import { IClaimReferralReward, IReferralRewardResponse } from './referral-reward.dto';

const BASE_URL = '/api/v1/ReferralReward';

export const GETReferralRewards = async () => {
	return requestHandler.call<IReferralRewardResponse[]>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};
export const POSTClaimReferralRewards = async (id: number) => {
	return requestHandler
		.call<IClaimReferralReward>({ url: `${BASE_URL}/claim?referralRewardId=${id}`, method: 'post' })
		.then(res => res.data);
};
