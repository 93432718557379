const getDesignToken = () => {
	return {
		colors: {
			navLink: {
				primary: '#4077A5',
				active: '#9DEBF2',
			},
			text: {
				primary: '#FFFFFF',
				secondary: '#637E92',
				green: '#5CFAE5',
			},
		},
	};
};

export default getDesignToken;
