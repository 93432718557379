import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabContainer = styled.section(
	({ theme }) => css`
		border-radius: 18px;
 		background: linear-gradient(0.92deg, rgba(255, 255, 255, 0.6) 60.24%, rgba(35, 135, 197, 0.27) 100.13%);
		padding:1.5px;
		margin-bottom: 20px;
		
		.border {
			box-shadow: 0px 6px 12px 0px #2F7AA7 inset;

			padding: 5px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			background: #7AC0ED;
			border-radius: 16px;
			.tab_item {
				padding: 11px 0 9px;
				font-size: 16px;
				font-weight: 400;
				line-height: 19px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #487C9C;

				&.active {
					background: linear-gradient(284.18deg, #902BF5 33.25%, #03DCCE 89.49%);
					color: ${theme.colors.text.primary};
					border-radius: 14px;
					font-weight: 600;
					box-shadow: 0px 4px 4px 0px #FFFFFFB8 inset;
					border: 1px solid #FFFFFFB8;
				}
			}
		}
	`,
);
