import { useAppSelector } from 'shared/hooks/redux-helper';
import { CoinsContainer } from '../css';
import numberUtils from 'shared/utils/number';
import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

const Coins = () => {
	const balance = useAppSelector(state => state.profile.balance);

	const [currentBalance, setCurrentBalance] = useState<number>(balance);
	const springProps = useSpring({
		currentBalance: balance,
		from: { currentBalance: currentBalance },
		config: { duration: 200 },
		onRest: () => setCurrentBalance(balance),
	});

	useEffect(() => {
		setCurrentBalance(balance);
	}, [balance]);

	return (
		<CoinsContainer>
			<img width='36px' src='/img/dollar.webp' />
			<animated.div>
				{springProps.currentBalance.to((value: number) => numberUtils.formatPriceWithDecimals(Math.round(value)))}
			</animated.div>
			{/* <div>{numberUtils.formatPriceWithDecimals(balance)}</div> */}
		</CoinsContainer>
	);
};

export default Coins;
