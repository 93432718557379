import { HeaderContainer } from '../css';

const Header = () => {
	return (
		<HeaderContainer>
			<h1>Earn more coins!</h1>
			<p>Complete tasks to earn more coins.</p>
		</HeaderContainer>
	);
};
export default Header;
