import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RewardsHistoryContainer = styled.div(
	() => css`
		padding: 24px 24px 160px;

		.top_light {
			width: 187px;
			height: 187px;
			background: rgba(145, 205, 219, 0.6);
			filter: blur(100px);
			position: absolute;
			left: 0;
			right: 0;
			top: -120px;
			margin: auto;
		}
	`,
);
