import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WelcomeModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		.header_img {
			width: 156px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -200px;
		}
		p {
			margin: 82px 0 20px;
			color: ${theme.colors.text.primary};
			padding: 0 24px;
		}

		.reward_container {
			background: #ffffff0d;
			padding: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			margin-bottom: 32px;

			img {
				width: 18px;
			}

			.title {
				color: ${theme.colors.text.primary};
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				margin-top: 1px;
			}
		}

		button {
			margin: 0 24px 24px;
			width: calc(100% - 48px);
		}
	`,
);
