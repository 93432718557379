import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { OverviewContainer } from '../css';
import { GetExchange } from 'api/v1/exchange';
import DotLoader from 'shared/components/dot-loader';

const Overview = () => {
	const profile = useAppSelector(state => state.profile);
	const [exchangeName, setExchangeName] = useState<string | null>(null);
	useEffect(() => {
		GetExchange().then(res => {
			setExchangeName(res);
		});
	}, []);
	return (
		<OverviewContainer>
			<div className='background'>
				<img src='/img/pineye/Subtract.webp' alt='' />
			</div>
			<div className='card_item_container  '>
				<div className='card_item'>
					<div className='pic right'>
						<img src='/img/pineye/new_profile.webp' alt='pin' width={43} height={43} />
					</div>
					<div className='text_container'>
						<div className='title'>PinEye</div>
						<div className='value'>Level {profile.level}</div>
					</div>
				</div>
			</div>
			<Link to='/exchange' className='card_item_container left '>
				<div className='card_item'>
					<div className='pic'>
						<img src='/img/icon/exchange.webp' alt='pin' width={38} height={38} />
					</div>
					<div className='text_container'>
						<div className='title'>Exchange</div>
						<div className='value'>{exchangeName === null ? <DotLoader /> : exchangeName === '' ? 'Choose' : exchangeName}</div>
					</div>
				</div>
			</Link>
		</OverviewContainer>
	);
};

export default Overview;
