import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Header, Referral, Special, Tab } from './components';
import { EarnContainer } from './css';

const Earn = () => {
	const [activeTab, setActiveTab] = useState<'SPECIAL' | 'REFERRAL'>('SPECIAL');



	return (
		<EarnContainer>
			<Helmet>
				<title>PinEye | earn</title>
			</Helmet>
			<div className='top_light'></div>
			<Header />
			<Tab activeTab={activeTab} setActiveTab={setActiveTab} />
			{activeTab === 'SPECIAL' && <Special />}
			{activeTab === 'REFERRAL' && <Referral />}
		</EarnContainer>
	);
};

export default Earn;
