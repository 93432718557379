import { HeaderContainer } from '../css';

const Header = () => {
	return (
		<HeaderContainer>
			<h1>Invite Friends!</h1>
			<p>Earn 500 Coins for Every Friend You Invite!</p>
		</HeaderContainer>
	);
};
export default Header;
