import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TicketCardsContainer = styled.div(
	() => css`
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
		gap: 14px;
		justify-content: center;
		justify-items: center;
		.border {
			background: linear-gradient(270deg, #ffffff -56.55%, rgba(153, 153, 153, 0) 70.78%);
			padding: 1px;
			border-radius: 9px;
			width: 100%;
			box-shadow: 0px 4px 4px 0px #115f8f;

			.card {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				padding: 12px 13px 6px;
				transition: all 0.3s ease;
				height: inherit;
				height: 100%;
				h2 {
					font-size: 14px;
					font-weight: 500;
					line-height: 15px;
					margin-bottom: 3px;
					text-align: center;
				}
				&.background_one {
					background: linear-gradient(189.43deg, #3b4bc6 8.58%, #3589bc 85.47%);
				}
				&.background_three {
					background: linear-gradient(189.43deg, #31acff 8.58%, #3589bc 85.47%);
				}
				&.background_five {
					background: linear-gradient(189.43deg, #2ca98e 8.58%, #3589bc 85.47%);
				}
				&.background_ten {
					background: linear-gradient(189.43deg, #9840ef 8.58%, #3589bc 85.47%);
				}

				img {
					width: 100%;
				}
				button {
					width: 100%;
					height: 25px;
					border-radius: 10px;
					padding: 1.5px;
					background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
					color: #ffffff;
					font-size: 11px;
					font-weight: 400;
					line-height: 13px;
					border: unset;
					.coin_container {
						display: flex;
						justify-content: center;
						align-items: center;
						background: #3585bc;
						border-radius: 8px;
						height: 100%;
						img {
							width: 13px;
							height: 13px;
							margin: 0;
							margin-right: 2px;
						}
					}
				}

				.number_container {
					display: flex;
					gap: 4px;
					margin-bottom: 13px;
					margin-top: 10px;
					.number_item {
						background: #ffffff52;
						width: 15px;
						height: 15px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-weight: 600;
						font-size: 16px;
						padding-top: 4px;
					}
				}
			}
		}
		@media (min-width: 390px) {
			gap: 18px 20px;
		}
		@media (max-width: 390px) {
			.border {
				.card {
					.number_container {
						margin-bottom: 8px;
						.number_item {
							width: 12px;
							height: 12px;
							font-size: 12px;
						}
					}
				}
			}
		}
	`,
);
