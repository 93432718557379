import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WelcomeContainer = styled.header(
	({ theme }) => css`
		display: grid;
		grid-template-columns: 40px 1fr;
		gap: 12px;
		padding: 0 24px;
		user-select: none;

		.profile_avatar {
			width: 40px;
			height: 40px;
			border-radius: 40px;
		}

		.value {
			color: ${theme.colors.text.primary};
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
			margin: 10px 0 auto 0;
		}
	`,
);
