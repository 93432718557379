import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { Coins, LevelProgress, Overview, Pineye } from './components';
import NotifModal from './components/notif-modal';
import { HomeContainer } from './css';
import { useDispatch } from 'react-redux';
import { changeNotifs } from 'store/reducers/profile';
import LotteryModal from './components/lottery-modal';

const Home = () => {
	const [notifModal, setNotifModal] = useState<boolean>(true);
	const [lotteryModalStatus, setLotteryModal] = useState<boolean>(true);
	const dispatch = useDispatch();
 
	useEffect(() => {
		window.Telegram.WebApp.BackButton.isVisible = false;
 		return () => {
			window.Telegram.WebApp.BackButton.isVisible = true;
		};
	}, []);
	useEffect(() => {
		window.scroll({ top: 2 });
		const touchCancel = (e: TouchEvent) => {
			e.preventDefault();
		};
		document.addEventListener('touchstart', touchCancel);
		return () => {
			document.removeEventListener('touchstart', touchCancel);
		};
	}, []);

	const notifs = useAppSelector(state => state.profile.notif);

	const onCloseNotifs = (state: boolean) => {
		setNotifModal(state);
		const newNotifs = notifs.filter(item => item.type === 'LotteryReward');
		dispatch(changeNotifs(newNotifs));
	};
	const onCloseLottery = (state: boolean) => {
		setLotteryModal(state);
		dispatch(changeNotifs([]));
	};

	return (
		<HomeContainer>
			<Helmet>
				<title>PinEye | home</title>
			</Helmet>
			{/* <div className='top_left_light'></div> */}
			{/* <Welcome /> */}
			<Overview />
			<Coins />
			<Pineye />
			<LevelProgress />
			{/* {welcomeModal && <WelcomeModal status={welcomeModal} setStatus={setWelcomeModal} />} */}

			{notifModal && notifs.filter(item => item.type !== 'LotteryReward').length > 0 && (
				<NotifModal status={notifModal} setStatus={onCloseNotifs} />
			)}
			{
			lotteryModalStatus && notifs.length === 1 && notifs.find(item => item.type === 'LotteryReward') && 
			(
				<LotteryModal status={true} setStatus={onCloseLottery} />
			)}
		</HomeContainer>
	);
};
export default Home;
