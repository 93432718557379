import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowRightOutline } from 'shared/components/icons';
import { Header, SecretCode } from './components';
import { GamesContainer } from './css';
import { GameRayVector } from './img';
import { canClaimReward } from 'api/v1/secret-code';
import toast from 'react-hot-toast';

const Games = () => {
	const [statusModal, setStatusModal] = useState(false);

	return (
		<>
			<GamesContainer>
				<Helmet>
					<title>PinEye | games</title>
				</Helmet>
				<div className='top_light'></div>
				<Header />

				<div className='game_list'>
					<div
						className='game_item secret_code'
						onClick={() => {
							canClaimReward().then(res => {
								if (!res.data.canClaim) {
									toast.error(res.data.cause);
									setStatusModal(false);
								} else {
									setStatusModal(true);
								}
							});
						}}
					>
						<GameRayVector className='ray_bg' />
						<div className='foreground'>
							<div className='title'>
								Secret Code <ArrowRightOutline />
							</div>
							<div className='description'>in to Telegram and Twitter</div>
							<img src='/img/game/game_items_secret_code.webp' alt='' className={'secret_code'} />
						</div>
					</div>
					<Link className='game_item lottery' to={'/games/lottery'}>
						<GameRayVector className='ray_bg' />
						<div className='foreground'>
							<div className='title'>
								Big Win Lottery <ArrowRightOutline />
							</div>
							<div className='description'>Take Your Chance to Win 10M Coins</div>
							<img src='/img/game/game_items_lottery.webp' alt='' />
						</div>
					</Link>
					<div className='game_item lucky_wheel'>
						<GameRayVector className='ray_bg' />
						<div className='foreground'>
							<div className='title'>Lucky Wheel</div>
							<div className='description'>Coming soon...</div>
							<img src='/img/game/game_item_lucky_wheel.webp' alt='' />
						</div>
					</div>
				</div>
			</GamesContainer>
			{statusModal && <SecretCode status={statusModal} setStatus={setStatusModal} />}
		</>
	);
};

export default Games;
