import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BoostersContainer = styled.section(
	({ theme }) => css`
		margin-top: 32px;
		color: ${theme.colors.text.primary};

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 13px;

			.booster_title {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			}
		}

		.cards_container {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
		.border {
			padding: 1px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			border-radius: 9px;
			.booster_card {
				background: #126090;
				border-radius: 8px;
				padding: 14px 20px 14px 14px;
				display: grid;
				grid-template-columns: 56px 1fr auto;
				gap: 5px 14px;
				.img_container_timer {
					grid-row: 1/3;
					width: 56px;
					height: 56px;
					background: transparent;
					border-radius: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					align-self: center;
					.full_icon {
						padding: 3px;
					}
				}
				.img_container {
					grid-row: 1/3;
					width: 44px;
					height: 44px;
					background: transparent;
					border-radius: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					align-self: center;
				}

				.title {
					font-weight: 400;
					font-size: 16px;
					line-height: 20px;
					margin: auto auto 0 0;
				}
				.value {
					font-weight: 400;
					font-size: 14px;
					line-height: 17px;
					color: ${theme.colors.text.primary};
					display: flex;
					align-items: flex-start;
					grid-row: 2;
					margin: 0 auto auto 0;
					flex-direction: column;
					.clock {
						width: 20px;
						height: 20px;
						margin-bottom: -1px;
					}
					.coin {
						display: flex;
						align-items: flex-end;
						gap: 3px;
					}
					img {
						width: 14px;
						margin-bottom: 3px;
					}
					.up {
						display: flex;
						align-items: flex-end;
						margin-left: -15px;
					}
					.clock_up {
						display: flex;
						align-items: flex-end;
						margin-left: -4px;
						span {
							margin-bottom: -2px;
						}
					}
				}

				.arrow,
				.green {
					grid-row: 1/3;
					margin: auto;
					width: 20px;
					height: 20px;
				}
				.green {
					color: ${theme.colors.text.green};
				}
			}
		}
	`,
);
