import { FC, ReactNode } from 'react';
import { CloseOutline } from '../icons';
import { ModalContainer } from './modal.style';
import { createPortal } from 'react-dom';

type ModalProps = {
	children: ReactNode;
	status: boolean;
	title: string;
	disableHeader?: boolean;
	backgroundColor?: string;
	onClose: () => void;
	isBottomSheet?: boolean;
};

const Modal: FC<ModalProps> = ({ children, status, onClose, title, disableHeader, backgroundColor, isBottomSheet }) => {
	return createPortal(
		<ModalContainer $isBottomSheet={isBottomSheet}>
			<div className={`background ${status ? 'active' : 'deactive'}`} onClick={onClose}></div>
			<div className={`modal_panel ${status ? 'active' : 'deactive'}`} style={{ background: backgroundColor ?? '' }}>
				{(disableHeader === undefined || disableHeader === false) && (
					<header className='header'>
						<h3>{title}</h3>
						<CloseOutline onClick={onClose} />
					</header>
				)}

				{children}
			</div>
		</ModalContainer>,
		document.body,
		'modal',
	);
};

export default Modal;
