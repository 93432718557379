/* eslint-disable @typescript-eslint/no-explicit-any */
import { GETTap } from 'api/v1/tap';
import toast from 'react-hot-toast';
import { Dispatch } from 'redux';
import { changeBalance, changeTap } from 'store/reducers/profile';

const UseTapSync = (dispatch: Dispatch) => {
	let tapCount: number = 0;
	let requestCount = 0;

	document.addEventListener('tap-count', (event: any) => {
		tapCount = event.detail as number;
	});

	setInterval(() => {
		if (tapCount > 0) {
			const _taps = tapCount;
			dispatch(changeTap(0));
			GETTap(_taps).then(res => {
				if (res.requestTapCount > res.appliedTapCount) {
					requestCount++;
				}
				if (res.requestTapCount > res.appliedTapCount && requestCount > 4) {
					toast.error('To prevent cheating and fair play, the number of clicks per second is limited.');
					requestCount = 0;
				}

				dispatch(changeBalance(res));
			});
		}
	}, 4000);
	return null;
};

export default UseTapSync;
