import Loader from '../loader';
import { SplashContainer } from './splash.style';

const Splash = () => {
	return (
		<SplashContainer>
			<Loader />
		</SplashContainer>
	);
};

export default Splash;
