import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ReferralContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		gap: 12px;

		.referral_card {
			background: linear-gradient(76.45deg, #0979bd 50.77%, #89bbda 105.62%);
			border-top: 1px solid #ffffff89;
			border-right: 1px solid #ffffff89;
			border-radius: 12px;
			padding: 14px;
			display: grid;
			grid-template-columns: 44px 1fr auto;
			grid-template-rows: 1fr 1fr;
			gap: 5px 14px;
			box-shadow: 0px 4px 4px 0px #ffffffb8 inset;

			&.claim_card {
				background: #136293;
			}
			&.with_progress {
				grid-template-rows: 1fr 1fr auto;
			}

			&.bold {
				scale: 1;
				opacity: 0.8;
			}

			.img_container {
				grid-row: 1/3;
				width: 44px;
				height: 44px;
				background: transparent;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 42px;
				}
			}

			.title {
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				margin: auto auto 0 0;
			}
			.value {
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				color: #03dcce;
				display: flex;
				align-items: flex-start;
				gap: 5px;
				grid-row: 2;
				margin: 0 auto auto 0;

				&.green {
					color: #28f39e;
				}

				img {
					width: 14px;
				}
			}

			svg {
				grid-row: 1/3;
				margin: auto;

				&.green {
					color: #28f39e;
				}
			}

			.claim_btn {
				grid-row: 1/3;
				margin: auto;
				background: linear-gradient(255.79deg, #34d899 28.77%, #0dac6f 62.19%);
				padding: 5px 16px;
				border-radius: 4px;
				font-weight: 600;
				font-size: 14px;
				line-height: 16px;
				box-shadow: 2px 3px 3px 0px #00000040;

				&.disabled {
					background: linear-gradient(255.79deg, #34d899 28.77%, #0dac6f 62.19%);
					opacity: 0.5;
					margin-bottom: 14px;
				}
			}
			.progress {
				display: flex;
				align-items: center;
				gap: 4px;
				justify-self: flex-end;
				color: ${theme.colors.text.primary};
				grid-column: 2/4;
				margin-top: -12px;
				font-size: 14px;
			}

			.progress_container {
				grid-column: 1/4;
				display: grid;
				grid-template-columns: auto 1fr;
				grid-template-rows: auto 10px;
				gap: 0px;
				color: ${theme.colors.text.primary};
				width: 100%;
				margin-top: -5px;
				background: linear-gradient(227.14deg, #7dcdff -1.29%, #1fa1f0 29.94%, #0074da 87.8%);
				padding: 6px;
				border-radius: 30px;
				box-shadow: 0px 1px 4px 0px #ffffffb8, inset 0px 2px 11px 0px #0000004a;

				.progress_empty {
					grid-row: 2;
					grid-column: 1/3;
					width: 98%;
					height: 12px;
					border-radius: 30px;
					background: #0958a8;
					overflow: hidden;

					.filled {
						background: linear-gradient(90deg, #6800d0 7.01%, #902bf5 31.49%, #5e6cff 63.44%, #54beff 97.94%);
						height: 11px;
						border-radius: 8px;
						width: 0;
						transition: all 300ms ease;
					}
				}
			}
		}
	`,
);
