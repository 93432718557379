import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InfoCardsContainer = styled.section(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		color: ${theme.colors.text.primary};
		gap: 12px;
		position: relative;
		z-index: 2;
		margin-bottom: 32px;

		.card_item {
			border: 1px solid #284c69;
			padding: 12px;
			display: grid;
			grid-template-columns: 56px 1fr;
			grid-template-rows: 1fr 1fr;
			gap: 4px 16px;
			border-radius: 8px;

			.img_container {
				width: 56px;
				height: 56px;
				grid-row: 1/3;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #243b55;
				border-radius: 4px;

				img {
					width: 46px;
				}

				.dollar_img {
					width: 32px;
					transform: rotate(45deg);
				}
			}

			.title {
				margin: auto 0 0 0;
				color: ${theme.colors.text.secondary};
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
			.value {
				margin: 0 0 auto 0;
				color: ${theme.colors.text.primary};
				font-weight: 600;
				font-size: 20px;
				line-height: 25px;
			}
		}
	`,
);
