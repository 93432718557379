import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LotteryModalContainer = styled.div(
	({ theme }) => css`
		position: relative;

		.header_img {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -110px;
		}

		.prize_container {
			margin: 48px 0 28px;
			background: #ffffff1f;
			padding: 12px 0 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 4px;

			.title {
				color: #c3def2;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}

			.prize {
				height: 27px;
			}
		}

		.winner_title {
			text-align: center;
			color: ${theme.colors.text.primary};
			font-weight: 600;
			font-size: 24px;
			line-height: 20px;
			margin-bottom: 24px;
		}

		.numbers_container {
			padding: 0 24px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 2px;
			margin-bottom: 24px;

			.number_item {
				width: 38px;
				height: 38px;
				border-radius: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #ffffff99;
				padding: 6px;

				.number {
					border-radius: 30px;
					background: #ffffff14;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 600;
					font-size: 18px;
					line-height: 20px;
					padding-top: 4px;
					color: ${theme.colors.text.primary};
				}
			}
		}

		.divider {
			background: radial-gradient(50% 12040.11% at 50% 0%, #80a8f4 0%, rgba(128, 168, 244, 0) 100%);
			height: 1px;
			width: 212px;
			margin: auto auto 16px;
		}

		.description {
			padding: 0 24px 24px;
			text-align: center;
			color: #c3def2;
			line-height: 20px;
			font-size: 14px;
		}
	`,
);
