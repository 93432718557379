import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LeaguesContainer = styled.div(
	({ theme }) => css`
		height: 100vh;
		display: flex;
		flex-direction: column;
		color: ${theme.colors.text.primary};
		gap: 10px;
		align-content: center;
		justify-content: space-between;
		background: rgb(18, 96, 144);
		background: linear-gradient(180deg, rgba(18, 96, 144, 1) 10%, #2774a3 30%, rgba(27, 135, 201, 1) 70%);
		padding-bottom: 50px;
	`,
);
