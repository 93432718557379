import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const IncomeStatsContainer = styled.div(
	({ theme }) => css`
		margin-bottom: 8px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-span: all;
		gap:16px;
		.box_container {
			border-radius: 8px;
			background: linear-gradient(184.42deg, #126090 1.87%, #3589bc 43.59%);
 			gap: 12px;
			padding: 20px 24px 13px;
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;
			align-items: center;
			h2 {
				color: ${theme.colors.text.primary};
				/* max-width: calc(100% - 100px); */
				font-size: 19px;
				font-weight: 400;
				line-height: 18px;
				text-align: center;
				span {
					font-size: 16px;
					font-weight: 400;
					line-height: 18px;
					text-align: center;
				}
			}

			img {
				width: 76px;
				height: 70px;
			}
			.link {
				display: flex;
				align-items: center;
				gap: 4px;
				font-size: 14px;
				font-weight: 400
				line-height: 17px;
				color: ${theme.colors.text.primary};
				border-radius:9px ;
				padding:1px;
				width: -webkit-fill-available;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) 10.29%, rgba(153, 153, 153, 0) 50%);
				.link_text{
					width: -webkit-fill-available;
					background: #3589BC;
					padding:  8px 12px;
					border-radius:8px ;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;
					a {
						color: ${theme.colors.text.primary};
						text-decoration: none;
					}
				}
			}
		}
	
		.rewards_container {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.reward_item {
				padding: 2px;
				background: linear-gradient(189deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				border-radius: 11px;
				.main_div{
						border-radius: 10px;
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 17px;
						padding:16px 12px;
						background: linear-gradient(180deg, rgba(147,193,221,1) 0%, 	#69A8CE 100%);
						&.friends{
							flex-direction: row;
							justify-content: space-around;
						}
						.title {
							font-size: 14px;
							font-weight: 400;
							line-height: 18px;
							text-align: center;
							color: #000;

						}
	
						.value {
							color: #126090;
							font-size: 22px;
							font-weight: 500;
							line-height: 18px;
							text-align: center;
							&.green {
								color: #4afda7;
							}
					}
				}
			
			}
		}
	`,
);
