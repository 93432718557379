import { FC } from 'react';
import { ILevelResponse } from 'api/v1/levels';
import { TopRanksContainer } from '../css';
import { Background } from '../img';
import numberUtils from 'shared/utils/number';

interface RankingProps {
	activeLevel: number;
	data: ILevelResponse[];
}

const TopRanks: FC<RankingProps> = ({ data, activeLevel }) => {
	const firstThree = data[activeLevel - 1].topPlayers.slice(0 ,3);

	
	return (
		<TopRanksContainer>
			<Background className='background' />
			<div className='pics'>
				<div className='two'>
					<img src='/img/leagues/two.webp' alt='' />
					<p  className='name'>{firstThree[1]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(firstThree[1]?.balance)}</p>
				</div>
                <div className='one'>
					<img src='/img/leagues/one.webp' alt='' />
					<p className='name'>{firstThree[0]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(firstThree[0]?.balance)}</p>
				</div>
                <div className='three'>
					<img src='/img/leagues/three.webp' alt='' />
					<p className='name'>{firstThree[2]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(firstThree[2]?.balance)}</p>
				</div>
			</div>
		</TopRanksContainer>
	);
};

export default TopRanks;
