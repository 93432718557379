import { FC, Fragment } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { NotifModalContainer } from '../css';
import { useAppSelector } from 'shared/hooks/redux-helper';

const NotifModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const notifs = useAppSelector(state => state.profile.notif);

	return (
		<Modal status={_status} onClose={onClose} title='' disableHeader={true} isBottomSheet>
			<NotifModalContainer>
				<img src='img/referral_daily_gift.webp' alt='' className='header_img' />
				<h2>Coins increased!</h2>
				{notifs.length > 0 &&
					notifs
						.filter(item => item.type !== 'LotteryReward')
						.map((item, index) => {
							const meta = JSON.parse(item.metatData);
							return (
								<Fragment key={`section_reward_item_${index}`}>
									{item.type === 'Welcome' && (
										<>
											<p>Welcome Bonus</p>
											<div className='reward_container'>
												<img src='/img/dollar.webp' alt='' className='dollar_img' />
												<div className='title'>
													Welcome Reward : {numberUtils.formatPriceWithDecimals(meta.Reward)}
												</div>
											</div>
										</>
									)}
									{item.type === 'DailyReferralReward' && (
										<>
											<p>Invite friends to join and get 30% extra coins based on their earnings every day!</p>
											<div className='friends_container'>
												<div className='title'>Your friends earning</div>
												<div className='value'>{numberUtils.formatPriceWithDecimals(meta.ReferralsBalance)}</div>
											</div>
											<div className='reward_container'>
												<img src='/img/dollar.webp' alt='' className='dollar_img' />
												<div className='title'>{numberUtils.formatPriceWithDecimals(meta.Reward)}</div>
											</div>
										</>
									)}
									{item.type === 'ExtraReferralReward' && (
										<>
											<p>1000 more coins for each referral above 10,000 people</p>
											<div className='reward_container'>
												<img src='/img/dollar.webp' alt='' className='dollar_img' />
												<div className='title'>{numberUtils.formatPriceWithDecimals(meta.Reward)}</div>
											</div>
										</>
									)}
								</Fragment>
							);
						})}

				<Button variant='primary' onClick={onClose}>
					Ok
				</Button>
			</NotifModalContainer>
		</Modal>
	);
};

export default NotifModal;
