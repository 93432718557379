import { useEffect, useState } from 'react';
import { FriendsList, Header, IncomeStats } from './components';
import { FriendsContainer } from './css';
import { GETFriends, IFriendResponse } from 'api/v1/friend';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';

const Friends = () => {
	const [data, setData] = useState<null | IFriendResponse>(null);

	const getInitData = () => {
		setData(null);
		GETFriends().then(res => {
			setData(res);
		});
	};
	useEffect(() => {
		getInitData();
	}, []);

	return (
		<FriendsContainer>
			<Helmet>
				<title>PinEye | Friends</title>
			</Helmet>
			<Header />
			{data ? (
				<>
					<IncomeStats friendsBalance={data.referralBalance?.yourFriendsBalanceIn24H} friendsCount={data.friendsCount} />
					<FriendsList data={data} />
				</>
			) : (
				<Loader />
			)}
		</FriendsContainer>
	);
};

export default Friends;
