import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const QrCodeContainer = styled.div(
	({ theme }) => css`
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;

		.title {
			color: ${theme.colors.text.primary};
			font-size: 18px;
			font-weight: 600;
		}

		img {
			width: 70%;
			border-radius: 14px;
		}

		a {
			color: ${theme.colors.text.primary};
			font-size: 16px;
			font-weight: 400;
		}
	`,
);
