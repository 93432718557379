import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BoostModalContainer = styled.section(
	({ theme }) => css`
		text-align: center;
		position: relative;
		color: ${theme.colors.text.primary};
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-row-gap: 16px;
		svg {
			width: 162px;
			height: 162px;
			position: absolute;
			top: -150px;
			left: 0px;
			right: 0;
			margin: auto;
			z-index: 3;
		}
		.multi {
			width: 162px;
			height: 162px;
			position: absolute;
			top: -172px;
			left: -21px;
			right: 0;
			margin: auto;
			z-index: 3;
		}
		.shadow {
			width: 137px;
			height: 23px;
			position: absolute;
			top: -25px;
			left: 0;
			right: 0;
			margin: auto;
			background: #00000033;
			border-radius: 100%;
			z-index: 2;
			filter: blur(1px);
		}
		h3 {
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			margin-top: 10px;
		}
		p {
			color: ${theme.colors.text.primary};
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
		}

		.reward_container {
			color: ${theme.colors.text.primary};
			font-size: 18px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;

			padding: 0 24px 8px;
		}

		.price_container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 12px;
			margin-bottom: 12px;
			img {
				width: 48px;
				height: 48px;
			}

			.title {
				color: ${theme.colors.text.primary};
				display: flex;
				align-items: center;
				margin-top: 1px;
				justify-content: center;
				gap: 12px;
				.prize {
					font-size: 30px;
					font-weight: 500;
					line-height: 37px;
				}
				span {
					font-size: 25px;
					font-weight: 400;
					line-height: 37px;
					color: #b0bcc5;
					display: flex;
					align-items: baseline;;
				}
			}
		}

		button {
			margin: 0 24px 52px;
			width: calc(100% - 48px);
		}
	`,
);
