import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LoaderContainer = styled.div<{ $isFullScreen?: boolean }>(
	({ $isFullScreen }) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		${$isFullScreen && `height: 100vh; position: fixed; top: 0; left: 0; right: 0; width: 100%;`}

		.lds-dual-ring {
			/* change color here */
			color: #fff;
		}

		.lds-dual-ring,
		.lds-dual-ring:after {
			box-sizing: border-box;
		}

		.lds-dual-ring {
			display: inline-block;
			width: 80px;
			height: 80px;
		}

		.lds-dual-ring:after {
			content: ' ';
			display: block;
			width: 64px;
			height: 64px;
			margin: 8px;
			border-radius: 50%;
			border: 6.4px solid currentColor;
			border-color: currentColor transparent currentColor transparent;
			animation: lds-dual-ring 1.2s linear infinite;
		}

		@keyframes lds-dual-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	`,
);
