/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { changeEnergy } from 'store/reducers/profile';

const UseEnergySync = (dispatch: Dispatch) => {
	setInterval(() => {
		dispatch(changeEnergy({ type: 'add' }));
	}, 1000);
	return null;
};

export default UseEnergySync;
