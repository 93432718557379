import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../button';
import { ArrowRightOutline } from '../icons';
import { WalkthroughContainer } from './walkthrough.style';
import { useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { useDispatch } from 'react-redux';
import { changeWalkthrough } from 'store/reducers/profile';

const Walkthrough = () => {
	const swiperRef = useRef<SwiperType | null>(null);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const lastEligibleIndex = 3;

	const dispatch = useDispatch();

	const swiperCards = [
		{ title: 'Tap and Earn', description: 'Boosting your mining efficiency in PinEye', img: '/img/walkthrough/1.webp' },
		{
			title: 'Daily Game',
			description: 'Every day, you can participate in a game and earn coins.',
			img: '/img/walkthrough/2.webp',
		},
		{
			title: 'Daily Task',
			description: 'Earn more coins by completing simple tasks.',
			img: '/img/walkthrough/3.webp',
		},
		{
			title: 'Connect Your Wallet',
			description: 'Connect your wallet to claim exclusive airdrop rewards.',
			img: '/img/walkthrough/4.webp',
		},
	];

	const onNextClickHandler = () => {
		if (swiperRef.current && activeIndex !== lastEligibleIndex) {
			swiperRef.current.slideNext();
			if (activeIndex === 1) {
				swiperRef.current.allowSlidePrev = false;
			}
		}

		if (activeIndex === lastEligibleIndex) {
			dispatch(changeWalkthrough());
		}
	};

	const onPrevClickHandler = () => {
		if (swiperRef.current && activeIndex !== 0) {
			swiperRef.current.slidePrev();
		}
	};

	return (
		<WalkthroughContainer>
			<Swiper
				spaceBetween={50}
				slidesPerView={1}
				onSlideChange={swiper => {
					if (swiper.activeIndex === lastEligibleIndex) {
						swiper.allowSlidePrev = false;
					}
					setActiveIndex(swiper.activeIndex);
				}}
				className='mySwiper'
				autoplay={{
					delay: 5000,
				}}
				pagination={true}
				navigation={{ nextEl: 'next_btn', prevEl: 'prev_btn' }}
				modules={[Pagination, Navigation]}
				onSwiper={swiper => {
					swiperRef.current = swiper;
				}}
			>
				{swiperCards.map((item, index) => (
					<SwiperSlide key={`swiper_item_${index}`}>
						<div className='slider_item'>
							<div className='header'>
								<div className='title'>{item.title}</div>
								<div className='value'>{item.description}</div>
							</div>
							<div className='img_container'>
								<img src={item.img} alt='' />
							</div>
						</div>
					</SwiperSlide>
				))}
				<Button
					className={`prev_btn ${swiperRef.current?.activeIndex === 0 ? 'disabled' : ''} ${
						swiperRef.current?.activeIndex === lastEligibleIndex ? 'hide' : ''
					}`}
					variant='primary'
					onClick={onPrevClickHandler}
				>
					<ArrowRightOutline />
				</Button>
				<Button
					className={`next_btn ${activeIndex === lastEligibleIndex ? 'play' : ''}`}
					variant='primary'
					onClick={onNextClickHandler}
				>
					{activeIndex < lastEligibleIndex ? <ArrowRightOutline /> : 'Play'}
				</Button>
			</Swiper>
		</WalkthroughContainer>
	);
};

export default Walkthrough;
