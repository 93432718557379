import React, { FC, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { canClaimReward, POSTClaimReward } from 'api/v1/secret-code';
import Modal from 'shared/components/modal';
import { CloseOutline } from 'shared/components/icons';
import Button from 'shared/components/button';
import Loader from 'shared/components/loader';
import { SecretCodeContainer } from '../css';
import { useDispatch } from 'react-redux';
import { changeBalance } from 'store/reducers/profile';

interface ISecretCodeBottomSheetProps {
	status: boolean;
	setStatus: (status: boolean) => void;
}

const SecretCodeBottomSheet: FC<ISecretCodeBottomSheetProps> = ({ status, setStatus }) => {
	const secretCodeInputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(true);
	const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);

	useEffect(() => {
		canClaimReward().then(res => {
			setLoading(false);
			if (!res.data.canClaim) {
				toast.error(res.data.cause);
				setStatus(false);
				setLoading(false);
			}
		});
	}, []);

	const close = () => {
		window.scroll({ top: 2 });
		setStatus(false);
		setLoading(false);
	};

	useEffect(() => {
		secretCodeInputRef.current?.focus();
		const handleResize = () => {
			if (window.scrollY > 100) {
				setIsKeyboardOpen(true);
			} else {
				setIsKeyboardOpen(false);
			}
		};

		window.addEventListener('scroll', handleResize);

		return () => {
			window.removeEventListener('scroll', handleResize);
		};
	}, []);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		window.scroll({ top: 2 });
		e.preventDefault();
		setLoading(true);

		if (secretCodeInputRef?.current?.value) {
			POSTClaimReward(secretCodeInputRef.current?.value)
				.then(res => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					dispatch(changeBalance({ balance: res.balance }));
					toast.success(res.message);
					close();
				})
				.catch(res => {
					toast.error(res.data.cause);
				})
				.finally(() => {
					window.scroll({ top: 2 });
					setLoading(false);
				});
		}
	};

	return (
		<Modal title={''} isBottomSheet status={status} onClose={close} disableHeader>
			<SecretCodeContainer style={{ paddingBottom: isKeyboardOpen ? '100px' : '10px' }}>
				<header>
					<img src={'/img/game/secret_code_popup.webp'} />
					<CloseOutline onClick={close} className={'close_button'} />
				</header>
				<form onSubmit={onSubmit}>
					<b className={'title'}>Secret Code</b>
					<p className={'description'}>
						Be one of the first 1,000 participants daily to enter the correct code and earn 100,000 coins. Enter the correct
						code now to claim your reward:
					</p>
					{loading ? (
						<Loader isFullScreen={false} />
					) : (
						<input
							ref={secretCodeInputRef}
							type='text'
							className={'secret_code_input'}
							required
							placeholder={'Past the Code here'}
							onBlur={() => {
								window.scroll({ top: 2 });
							}}
						/>
					)}

					<Button disabled={loading} variant={'primary'} type={'submit'}>
						Check
					</Button>
				</form>
			</SecretCodeContainer>
		</Modal>
	);
};

export default SecretCodeBottomSheet;
