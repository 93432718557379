import { GETLevels } from 'api/v1/levels';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { LightingBold } from 'shared/components/icons';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { changeLevels } from 'store/reducers/levels';
import { LevelProgressContainer } from '../css';

const LevelProgress = () => {
	const profile = useAppSelector(state => state.profile);
	const levels = useAppSelector(state => state.levels);
	const dispatch = useDispatch();
	
	useEffect(() => {
		GETLevels().then(res => {
			dispatch(changeLevels(res));
		});
	}, []);
	const currentLevel = levels?.find(item => item.level === profile.level);

 	return (
		<LevelProgressContainer>
			<div className='flower_container'>
				<img src={`/img/pineye/flower_${currentLevel?.level || 1}.webp`} width={25} height={25} alt='' />
				<Link className='progress_container ' to={'/leagues'}>
					<div className='border'>
						{currentLevel && (
							<div
								className='filled'
								style={{
									width:
										profile.balance > currentLevel.toBalance
											? '100%'
											: `${(profile.balance * 100) / currentLevel.toBalance}%`,
								}}
							></div>
						)}
					</div>
				</Link>
				<img src={`/img/pineye/flower_${(currentLevel?.level || 0) + 1}.webp`} width={25} height={25} alt='' />
			</div>
			<div className='info'>
				<div>
					<Link to={'/boost'} className='energy'>
						<LightingBold />{' '}
						<p>
							{profile?.energy?.currentEnergy}/{profile?.energy?.maxEnergy}
						</p>
					</Link>
				</div>
				<div>
					<Link className='level' to={'/leagues'}>
						<img src='/img/pineye/cup.webp' width={25} height={25} alt='' /> {currentLevel?.title}
					</Link>
				</div>
				<div>
					<Link to={'/boost'} className='boost'>
						<img src='/img/pineye/jet.webp' width={25} height={25} alt='' />
						Boost
					</Link>
				</div>
			</div>
		</LevelProgressContainer>
	);
};
export default LevelProgress;
