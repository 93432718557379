import { Helmet } from 'react-helmet';
import { Boosters, Header } from './components';
import { BoostContainer } from './css';

const Boost = () => {

	return (
		<BoostContainer>
			<Helmet>
				<title>PinEye | boost</title>
			</Helmet>
			<div className='top_light'></div>
			<Header />

			<Boosters />
		</BoostContainer>
	);
};

export default Boost;
