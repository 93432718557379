import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FriendsListContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};

		.header {
			display: flex;
			justify-content: space-between;
			color: ${theme.colors.text.primary};
			align-items: center;
			margin-bottom: 8px;

			h2 {
				font-size: 20px;
				font-weight: 600px;
				line-height: 25px;

				small {
					font-weight: 300;
					font-size: 12px;
				}
			}

			div {
				display: flex;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}

		.empty_container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: center;
			justify-content: center;
			padding: 24px 0 20px;

			p {
				font-size: 14px;
				font-weight: 400px;
				line-height: 17px;
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}

		.list_container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 24px 0 20px;
			.list_item {
				padding: 1px;
				background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				border-radius: 9px;
				.list_item_inner {
					background: #126090;
					padding: 10px 16px;
					border-radius: 8px;
					display: grid;

					grid-template-columns: 1fr 14px 60px;
					gap: 12px;
					align-items: center;
					justify-content: space-between;

					.title {
						font-size: 13px;
						font-weight: 400;
						line-height: 15px;
						text-align: left;
						color: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						span {
							font-size: 16px;
							line-height: 19px;
						}
					}

					.value {
						display: flex;
						align-items: center;
						gap: 6px;
						color: #fff;
						justify-content: space-around;
						p {
							font-size: 16px;
							line-height: 20px;
							font-weight: 400;
							color: #28c7f3;
						}
					}
					img {
						width: 20px;
						margin-bottom: 2px;
					}
				}
			}
		}

		.invite_container {
			display: grid;
			grid-template-columns: 1fr 48px;
			gap: 8px;

			position: fixed;
			bottom: 104px;
			width: calc(100% - 48px);

			.invite_btn {
				background: linear-gradient(90deg, #00ffdd 0%, #5a6ff0 100%);
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
				gap: 4px;
				color: ${theme.colors.text.primary};

				svg {
					margin-bottom: 1px;
				}
			}

			.copy_btn {
				background: #5a70f0;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: ${theme.colors.text.primary};
			}
		}
	`,
);
