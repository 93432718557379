import styled from '@emotion/styled';

export const WalletBalanceContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	color: white;
	padding-bottom: 90px;
	.title {
		font-size: 18px;
		font-weight: 500;
		line-height: 22.28px;
		text-align: left;
	}
	.wallet_container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
	.wallet {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #112336;
		padding: 16px 12px 12px 12px;
		border-radius: 27px;
		border: 1.5px solid #ffffff;
		box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
	}

	.pineye {
		background: radial-gradient(50.9% 55.46% at 50.45% 40.31%, #ffbb21 0%, #d87700 90.5%);
	}
	.usdt {
		background: radial-gradient(50.9% 55.46% at 50.45% 40.31%, #2ce7bd 0%, #179b7a 90.5%);
	}
	.not {
		background: radial-gradient(50.9% 55.46% at 50.45% 40.31%, #8f8f8f 0%, #000000 86%);
	}
	.detail {
		display: flex;
		flex-direction: column;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.41px;
		text-align: center;
		gap: 8px;
		p {
			font-size: 16px;
			font-weight: 500;
			line-height: 19.41px;
			margin-top: 4px;
		}
		.tokenBalance {
			padding: 2px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
			border-radius: 27px;
			div {
				display: flex;
				align-items: flex-end;
				gap: 3px;
				justify-content: center;
				/* margin: 0 auto auto 0; */
				border-radius: 24px;
				height: 30px;
				align-items: baseline;
				padding-top: 8px;
			}
		}
	}
	button {
		color: inherit;
		max-width: 100px;
		max-height: 32px;
		background: #25486b;
		border-radius: 2rem;
		border: none;
		appearance: none;
		padding: 0.5rem 2rem;
		font-size: 14px;
		margin: auto;
	}

	@media (min-width: 375px) {
		.detail {
			.tokenBalance {
				width: 120px;
			}
		}
	}
`;
