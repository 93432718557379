import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalkthroughContainer = styled.div(
	({ theme }) => css`
		height: 100vh;

		.mySwiper {
			height: calc(100% - 40px);
			padding: 40px 24px;

			.slider_item {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				color: ${theme.colors.text.primary};
				height: 100%;

				.header {
					text-align: center;
					display: flex;
					flex-direction: column;
					gap: 8px;
					.title {
						font-size: 24px;
						font-weight: 800;
						line-height: 34px;
					}

					.value {
						font-size: 16px;
						font-weight: 400;
						line-height: 26px;
					}
				}
				.img_container {
					position: relative;
					&::after {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 100px;
						content: '';
						background: linear-gradient(0deg, #2271a1 30%, transparent 100%);
					}
					img {
						width: 100%;
					}
				}
			}
		}

		.swiper-pagination-bullet {
			background: #50e5da;
			opacity: 0.3;

			&.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}

		.next_btn {
			right: 24px;
			bottom: 0;
			position: absolute;
		}
		.prev_btn {
			left: 24px;
			bottom: 0;
			position: absolute;

			svg {
				transform: rotate(180deg);
			}
		}

		.next_btn.disabled,
		.prev_btn.disabled {
			opacity: 0.5;
		}
		.prev_btn.hide {
			display: none;
		}
		.next_btn,
		.prev_btn {
			padding: 11px 11px 9px !important;
			z-index: 11;
		}

		.play {
			width: calc(100% - 48px);
		}
	`,
);
