import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.div(
	({ theme }) => css`
		padding: 24px 24px 120px;
		background: linear-gradient(180deg, #2775A6 0%, rgba(127,178,211,1) 36%, rgba(34,113,161,1) 100%);

		.top_light {
			width: 187px;
			height: 187px;
			background: rgba(145, 205, 219, 0.6);
			filter: blur(100px);
			position: absolute;
			left: 0;
			right: 0;
			top: -120px;
			margin: auto;
		}

		.game_list {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.game_item {
				width: 100%;
				position: relative;
				border-radius: 8px;
				overflow: hidden;
				height: 83px;
				.ray_bg {
					position: absolute;
					right: 0;
					bottom: 0;
				}
				.foreground {
					position: relative;
					height: 100%;
					padding: 16px 20px 20px;
					color: ${theme.colors.text.primary};

					.title {
						display: flex;
						align-items: center;
						gap: 2px;
						font-weight: 600;
						font-size: 18px;
						line-height: 22px;
						margin-bottom: 8px;

						svg {
							width: 12px;
							height: 12px;
						}
					}
					.description {
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
					}
					img {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 28%;
					  &.secret_code{
						width: 40%;
					  }
					}
				}


              &.secret_code {
                background: #d477e9;
                .foreground {
                  background: linear-gradient(94.48deg, #3672b8 17.37%, rgba(54, 114, 184, 0) 99.62%);
                }
              }
			  
				&.lottery {
					background: #73e8ff;
					.foreground {
						background: linear-gradient(94.48deg, #3672b8 17.37%, rgba(54, 114, 184, 0) 99.62%);
					}
				}
				&.lucky_wheel {
					background: #e534f4;
					.foreground {
						background: linear-gradient(94.48deg, #ff9212 17.37%, rgba(227, 123, 0, 0) 99.62%);
						img {
							width: unset;
							height: 100%;
						}
					}
				}
			}
		}
	`,
);
