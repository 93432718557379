import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducers/profile';
import Levels from  './reducers/levels';
export default configureStore({
	reducer: {
		profile: Profile,
		levels: Levels
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
