import { NavLink, useLocation } from 'react-router-dom';
import { WAllet, Earn, Game, FriendsActive } from './img';
import { MenuContainer } from './menu.style';

const Menu = () => {
	const location = useLocation();
	const isActive = (path: string) => location.pathname === path;

	const renderIcon = (path: string, ActiveIcon: React.FC, InactiveIcon: React.FC) => {
		return isActive(path) ? <ActiveIcon /> : <InactiveIcon />;
	};

	const onNavItemClick = () => {
		window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
	};

	return (
		<MenuContainer>
			<div className='inner_nav_container'>
				<NavLink
					to={'/friends'}
					onClick={() => {
						!isActive('/friends') && onNavItemClick();
					}}
				>
					{isActive('/friends') && <div className='active_item ' />}

					{renderIcon('/friends', FriendsActive, FriendsActive)}
					<p>Friends</p>
				</NavLink>
				<NavLink
					to='/games'
					onClick={() => {
						!isActive('/games') && onNavItemClick();
					}}
				>
					{isActive('/games') && <div className='active_item ' />}

					{renderIcon('/games', Game, Game)}
					<p>Games</p>
				</NavLink>

				<NavLink
					to='/'
					className='center'
					onClick={() => {
						!isActive('/') && onNavItemClick();
					}}
				>
					<img src={isActive('/') ? `/img/gif-home.gif` : '/img/eye.webp'} alt='' width={'53px'} height={'53px'} />
				</NavLink>

				<NavLink
					to={'/earn'}
					onClick={() => {
						!isActive('/earn') && onNavItemClick();
					}}
				>
					{isActive('/earn') && <div className='active_item ' />}

					{renderIcon('/earn', Earn, Earn)}
					<p>Earn</p>
				</NavLink>
				<NavLink
					className='last'
					to={'/wallet'}
					onClick={() => {
						!isActive('/wallet') && onNavItemClick();
					}}
				>
					{isActive('/wallet') && <div className='active_item ' />}

					{renderIcon('/wallet', WAllet, WAllet)}
					<p>Wallet</p>
				</NavLink>
			</div>
		</MenuContainer>
	);
};

export default Menu;
