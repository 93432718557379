import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FriendsListContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};

		.header {
			display: flex;
			justify-content: space-between;
			color: ${theme.colors.text.primary};
			align-items: center;
			/* margin-bottom: 8px; */

			h2 {
				font-size: 20px;
				font-weight: 500;
				line-height: 25px;
				text-align: left;
				 
			}
		}

		.empty_container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: center;
			justify-content: center;
			padding: 24px 0 20px;

			p {
				font-size: 14px;
				font-weight: 400px;
				line-height: 17px;
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}

		.list_container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 12px 0 20px;

			.list_item {
				padding: 1px;
				background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				border-radius: 9px;
				.list_item_inner {
					background: #126090;
					padding: 10px 16px;
					border-radius: 8px;
					display: grid;

					grid-template-columns: 1fr 14px 60px;
					gap: 12px;
					align-items: center;
					justify-content: space-between;
					.title {
						display: flex;
						align-items: center;
						color: #fff;
						gap:12px;
						p {
							display: flex;
							flex-direction: column;
							gap: 1px;
							font-size: 16px;
							font-weight: 400;
							line-height: 19.41px;
							text-align: left;
							.level_title {
								font-size: 12px;
								font-weight: 400;
								line-height: 14.56px;
								text-align: left;
							}
						}

						img {
							width: 40px;
							height: 40px;
						}
					}

					.value {
						display: flex;
						align-items: center;
						gap: 6px;
						color: #fff;
						justify-content: space-around;
						p {
							font-size: 16px;
							line-height: 20px;
							font-weight: 400;
							color: #28c7f3;
						}
					}
					img {
						width: 20px;
						margin-bottom: 2px;
					}

					&:last-of-type {
						border: none;
					}
				}
			}
		}

		.invite_container {
			display: grid;
			grid-template-columns: 1fr 48px;
			gap: 8px;
			position: fixed;
			z-index: 2;
			bottom: 120px;
			width: calc(100% - 48px);

			.invite_btn {
				background: linear-gradient(272.78deg, #902bf5 14.14%, #03dcce 91.66%);
				border-radius: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
				gap: 4px;
				color: ${theme.colors.text.primary};
				border: 1.4px solid #9cefebb2;
				box-shadow: 0px 4px 4px 0px #ffffffb8 inset;

				svg {
					margin-bottom: 1px;
				}
			}

			.copy_btn {
				background: #902cf6;
				border-radius: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: ${theme.colors.text.primary};
				border: 1.4px solid #9cefebb2;
				box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
			}
		}
	`,
);
