import { LoaderContainer } from './loader.style';

type LoaderPropType = {
	isFullScreen?: boolean;
};
const Loader = ({ isFullScreen = true }: LoaderPropType) => {
	return (
		<LoaderContainer $isFullScreen={isFullScreen}>
			<div className='lds-dual-ring'></div>
		</LoaderContainer>
	);
};

export default Loader;
