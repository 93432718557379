import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { ButtonContainer, LinkContainer } from './button.style';
import DotLoader from '../dot-loader';

const Button: FC<{
	className?: string;
	variant: 'primary' | 'outline';
	children: ReactNode;
	isLoading?: boolean;
	disabled?: boolean;
	href?: string;
	target?: string;
	onClick?: (e: { preventDefault(): void; target: unknown }) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, variant, className, isLoading, disabled, href, target, onClick }) => {
	return href ? (
		<LinkContainer
			className={`${variant} ${className ?? ''} ${disabled ? 'disabled' : ''}`}
			href={href}
			{...(target && { target: target })}
			{...(onClick && { onClick: onClick })}
		>
			{isLoading ? <DotLoader /> : children}
		</LinkContainer>
	) : (
		<ButtonContainer
			className={`${variant} ${className ?? ''} ${disabled ? 'disabled' : ''}`}
			disabled={disabled !== undefined ? disabled : isLoading === true ? true : undefined}
			onClick={onClick}
		>
			{isLoading ? <DotLoader /> : children}
		</ButtonContainer>
	);
};

export default Button;
