import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
	({ theme }) => css`
		position: relative;
		z-index: 2;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;

		h1 {
			font-size: 24px;
			font-weight: 400;
			line-height: 29px;
			margin: 0;
		}

		p {
			color: #000;
			font-size: 16px;
			font-weight: 400;
			max-width: 340px;
			text-align: center;
			line-height: 20px;
			margin-bottom: 28px;
		}

		.note {
			color: #fff;
			font-size: 14px;
			font-weight: 400;

			strong {
				color: ${theme.colors.text.primary};
				font-weight: 600;
			}
		}
	`,
);
