import Boost from 'pages/boost';
import Earn from 'pages/earn';
import Friends from 'pages/friends';
import Games from 'pages/games';
import Home from 'pages/home';
import Leagues from 'pages/leagues';
import Lottery from 'pages/lottery';
import RewardHistory from 'pages/reward-history';
import Wallet from 'pages/wallet';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'shared/components/layout/layout';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { envResolver } from 'shared/configs/env-resolver';
import Exchange from 'pages/exchange';

const manifestUrl = envResolver.TON_MANIFEST;

const App = () => {
	return (
		<TonConnectUIProvider
			actionsConfiguration={{
				twaReturnUrl: 'https://t.me/pineye_bot',
			}}
			manifestUrl={manifestUrl}
		>
			<BrowserRouter>
				<Toaster
					position='top-center'
					containerStyle={{
						zIndex: 9999999999,
					}}
					containerClassName='toaster_container'
				/>
				<Layout>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/friends' element={<Friends />} />
						<Route path='/earn' element={<Earn />} />
						<Route path='/boost' element={<Boost />} />
						<Route path='/leagues' element={<Leagues />} />
						<Route path='/reward-history' element={<RewardHistory />} />
						<Route path='/games' element={<Games />} />
						<Route path='/games/lottery' element={<Lottery />} />
						<Route path='/wallet' element={<Wallet />} />
						<Route path='/exchange' element={<Exchange />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</TonConnectUIProvider>
	);
};

export default App;
