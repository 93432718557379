import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LotteryContainer = styled.div(
	({ theme }) => css`
		position: relative;
		background: linear-gradient(180deg, #2372A2 0%, rgba(127,178,211,1) 40%, rgba(34,113,161,1) 100%);

		.main_container {
			position: relative;
			padding: 20px 24px 100px;
			color: ${theme.colors.text.primary};

			h1 {
				font-size: 22px;
				font-weight: 400;
				line-height: 15px;
				text-align: center;
				margin-bottom: 16px;
			}

			.note {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				margin-bottom: 11px;
			}
			p {
				color: #b0bcc5;
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 32px;
			}
		}

		.last_day_winner {
			background: #062e5c;
			display: flex;
			position: relative;

			.info {
				position: absolute;
				top: 16px;
				left: 24px;
				display: flex;
				flex-direction: column;
				gap: 8px;

				.title {
					color: #b0bcc5;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
				}
				.value {
					color: ${theme.colors.text.primary};
					font-weight: 600;
					font-size: 24px;
					line-height: 20px;
				}
			}
			img {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
			}
		}
	`,
);
