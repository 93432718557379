import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BalanceList, Highlight } from './components';
import { WalletContainer } from './css';
import { TonConnectButton, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { GETAllWallets, POSTConnectWallet, POSTDisconnectWallet } from 'api/v1/wallet';
import { Helmet } from 'react-helmet';
const Wallet = () => {
	const [isConnected, setIsConnected] = useState<boolean>();
	const [connectedWallets, setConnectedWallets] = useState({ address: '', walletType: '' });
	const walletInfo = useTonWallet();
	const userFriendlyAddress = useTonAddress();

	useEffect(() => {
		GETAllWallets().then(res => {
			res.length > 0 ? setIsConnected(true) : setIsConnected(false);
			setConnectedWallets({
				address: res[0].address,
				walletType: res[0].walletType,
			});
		});
	}, [walletInfo]);

	useEffect(() => {
		if (isConnected === false && walletInfo?.device) {
			POSTConnectWallet(walletInfo.device.appName, userFriendlyAddress);
		}
	}, [isConnected, walletInfo, userFriendlyAddress]);
	useEffect(() => {
		if (!walletInfo && isConnected) {
			POSTDisconnectWallet(connectedWallets.walletType, connectedWallets.address).then(() => {
				setIsConnected(false);
			});
		}
	}, [walletInfo, isConnected]);

	return (
		<WalletContainer>
			<Helmet>
				<title>PinEye | Wallet</title>
			</Helmet>
			<h1>Ongoing Campaign</h1>
			<NavLink to={'/wallet'} className={'campaign_banner'}>
				<img src={'/img/wallet/banner-header.webp'} />
			</NavLink>
			<Highlight />
			<BalanceList />

			<TonConnectButton className={'connect_wallet'} />
		</WalletContainer>
	);
};
export default Wallet;
