import { QrCodeContainer } from './qr-code.style';

const QrCode = () => {
	return (
		<QrCodeContainer>
			<div className='title'>Play on your mobile</div>
			<img src='/img/qrcode.webp' alt='' />
			<a href='https://t.me/pinEye_bot' rel='noopener noreferrer'>
				@pinEye_bot
			</a>
		</QrCodeContainer>
	);
};

export default QrCode;
