import { GETLevels } from 'api/v1/levels';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'shared/components/loader';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { Pineye, Ranking, TopRanks } from './components';
import { LeaguesContainer } from './css/leagues.style';
import { changeLevels } from 'store/reducers/levels';
import { useDispatch } from 'react-redux';

const Leagues = () => {
	const levels = useAppSelector(state => state.levels);
	const currentLevel = useAppSelector(state => state.profile.level);
	const dispatch = useDispatch();
	const [activeLevel, setActiveLevel] = useState<number>(currentLevel ?? 1);

	const onChangeActiveLeague = (type: 'NEXT' | 'PREV') => {
		if (type === 'NEXT' && activeLevel < 10) {
			setActiveLevel(prev => prev + 1);
		}
		if (type === 'PREV' && activeLevel > 1) {
			setActiveLevel(prev => prev - 1);
		}
	};

	useEffect(() => {
		GETLevels().then(res => {
			dispatch(changeLevels(res));
		});
	}, []);

	return (
		<LeaguesContainer>
			<Helmet>
				<title>PinEye | Leagues</title>
			</Helmet>

			{levels && currentLevel ? (
				<div className='main_container'>
					<Pineye data={levels} activeLevel={activeLevel} onChangeActiveLeague={onChangeActiveLeague} />
					<TopRanks data={levels} activeLevel={activeLevel} />
					<Ranking data={levels} activeLevel={activeLevel} />
				</div>
			) : (
				<Loader />
			)}
		</LeaguesContainer>
	);
};

export default Leagues;
