import { HeaderContainer } from '../css';

const Header = () => {
	return (
		<HeaderContainer>
			<h1>Daily Game</h1>
			<p>Every day, you can participate in a game and earn coins.</p>
		</HeaderContainer>
	);
};
export default Header;
