import { FC, useState } from 'react';
import { TicketCardsContainer } from '../css';
import BuyModal from './buy-modal';
import { ILotteryResponse } from 'api/v2/lottery';
import numberUtils from 'shared/utils/number';
import { useAppSelector } from 'shared/hooks/redux-helper';
import Loader from 'shared/components/loader';

interface ITicketCardsdata {
	data: ILotteryResponse;
	setHasBuyed: (hasBuyed: boolean) => void;
	setCodes: (codes: string[]) => void;
}

const TicketCards: FC<ITicketCardsdata> = ({ setHasBuyed, setCodes, data }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [cost, setCost] = useState<number | null>(null);
	const [count, setCount] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const balance = useAppSelector(state => state.profile.balance);
	return (
		<>
			<TicketCardsContainer>
				{isLoading && <Loader />}
				<div className='border'>
					<div
						className='card background_one'
						onClick={() => {
							setCost(+data.lotteryRule.cost);
							setCount(1);
							setIsOpen(true);
						}}
					>
						<h2>Purchase 1 ticket</h2>
						<img src={`/img/lottery/one-ticket.webp`} alt='' />

						<div className='number_container'>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
						</div>
						<button
							disabled={balance < +data.lotteryRule.cost}
							onClick={() => {
								setCost(+data.lotteryRule.cost);
								setCount(1);
								setIsOpen(true);
							}}
						>
							<div className='coin_container'>
								<img width={'13px'} height={'13px'} src={`/img/coin/pineye.webp`} alt='' />{' '}
								{numberUtils.formatPriceWithDecimals(+data.lotteryRule.cost)}
							</div>
						</button>
					</div>
				</div>
				<div className='border'>
					<div
						className='card background_three'
						onClick={() => {
							setCost(+data.lotteryRule.cost);
							setCount(3);
							setIsOpen(true);
						}}
					>
						<h2>Purchase 3 tickets</h2>
						<img src={`/img/lottery/three-ticket.webp`} alt='' />

						<div className='number_container'>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
						</div>
						<button
							disabled={balance < +data.lotteryRule.cost}
							onClick={() => {
								setCost(+data.lotteryRule.cost * 3);
								setCount(3);
								setIsOpen(true);
							}}
						>
							<div className='coin_container'>
								<img width={'13px'} height={'13px'} src={`/img/coin/pineye.webp`} alt='' />{' '}
								{numberUtils.formatPriceWithDecimals(+data.lotteryRule.cost * 3)}
							</div>
						</button>
					</div>
				</div>
				<div
					className='border'
					onClick={() => {
						setCost(+data.lotteryRule.cost);
						setCount(5);
						setIsOpen(true);
					}}
				>
					<div className='card background_five'>
						<h2>Purchase 5 tickets</h2>
						<img src={`/img/lottery/five-ticket.webp`} alt='' />

						<div className='number_container'>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
						</div>
						<button
							disabled={balance < +data.lotteryRule.cost}
							onClick={() => {
								setCost(+data.lotteryRule.cost * 5);
								setCount(5);
								setIsOpen(true);
							}}
						>
							<div className='coin_container'>
								<img width={'13px'} height={'13px'} src={`/img/coin/pineye.webp`} alt='' />{' '}
								{numberUtils.formatPriceWithDecimals(+data.lotteryRule.cost * 5)}
							</div>
						</button>
					</div>
				</div>
				<div className='border'>
					<div
						className='card background_ten'
						onClick={() => {
							setCost(+data.lotteryRule.cost);
							setCount(10);
							setIsOpen(true);
						}}
					>
						<h2>Purchase 10 tickets</h2>
						<img src={`/img/lottery/ten-ticket.webp`} alt='' />

						<div className='number_container'>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
							<div className='number_item'>{'?'}</div>
						</div>
						<button
							disabled={balance < +data.lotteryRule.cost}
							onClick={() => {
								setCost(+data.lotteryRule.cost * 10);
								setCount(10);
								setIsOpen(true);
							}}
						>
							<div className='coin_container'>
								<img width={'13px'} height={'13px'} src={`/img/coin/pineye.webp`} alt='' />{' '}
								{numberUtils.formatPriceWithDecimals(+data.lotteryRule.cost * 10)}
							</div>
						</button>
					</div>
				</div>
			</TicketCardsContainer>
			{isOpen && (
				<BuyModal
					cost={cost}
					isOpen={isOpen}
					count={count}
					setHasBuyed={setHasBuyed}
					setCodes={setCodes}
					data={data}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
					onClose={() => {
						setCost(null);
						setIsOpen(false);
						setCount(1);
					}}
				/>
			)}
		</>
	);
};

export default TicketCards;
