import { TouchEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { changeTap } from 'store/reducers/profile';
import { PineyeContainer } from '../css/pineye.style';

const Pineye = () => {
	const profile = useAppSelector(state => state.profile);

	const dispatch = useDispatch();
	const [clickPositions, setClickPositions] = useState<{ x: number; y: number; id: number }[]>([]);

	const touchHandler = (e: TouchEvent<HTMLElement>) => {
		if (profile.energy && profile.energy.currentEnergy > profile.earnPerTap) {
			window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
			for (let index = 0; index < Math.min(e.targetTouches.length, 2); index++) {
				const touch = e.targetTouches[index];

				const rect = e.currentTarget.getBoundingClientRect();
				const x = touch.clientX - rect.left;
				const y = touch.clientY - rect.top;
				const id = Math.random();
				setClickPositions(prevPositions => [...prevPositions, { x, y, id: x + y + id }]);
				setTimeout(() => {
					setClickPositions(prevPositions => prevPositions.filter(pos => pos.id !== x + y + id));
				}, 1000);
			}

			dispatch(changeTap(e.targetTouches.length));
			if (window.scrollY !== 2) {
				window.scroll({ top: 2 });
			}
		}
	};

	return (
		<PineyeContainer level={profile.level ?? 1} onTouchStart={touchHandler}>
			<img src={`/img/pineye/Level_${profile.level ?? 1}_.webp`} draggable={false} />
			{/* <div className={`character_light style_${profile.level ?? 1}`}></div> */}
		
			{/* <div className='left_light'></div>
			<div className='right_light'></div> */}

			{clickPositions.map(pos => (
				<div key={pos.id} className='animated_text' style={{ left: `${pos.x}px`, top: `${pos.y}px` }}>
					+{profile.earnPerTap}
				</div>
			))}
		</PineyeContainer>
	);
};

export default Pineye;
