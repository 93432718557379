import { POSTClaimSocialFollower } from 'api/v1/social-follower';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import { SocialModalContainer } from '../css';
import toast from 'react-hot-toast';

const SocialModal: FC<{
	status: boolean;
	title: string;
	reward: number;
	link: string;
	id: number;
	type: number;
	setStatus: (status: boolean) => void;
}> = ({ status, title, reward, link, id, type, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [canCheck, setCanCheck] = useState<boolean>(link.includes('twitter') || link.includes('coinmarketcap') ? false : true);
	const dispatch = useDispatch();
	const onCheckClickHandler = () => {
		setIsLoading(true);
		POSTClaimSocialFollower(id)
			.then(res => {
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
				dispatch(changeBalance(res));
				dispatch(changeReplay());
				toast.success('reward has been claimed successfully');
				onClose();
			})
			.catch(() => {
				setCanCheck(true);
				window.open(link, '_blank')
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Modal status={_status} onClose={onClose} title={''} isBottomSheet>
			<SocialModalContainer>
				<img
					className='header_pic'
					src={
						type === 0
							? '/img/earn/telegram-modal.webp'
							: type === 1
							? '/img/earn/x-modal.webp'
							: type === 2
							? '/img/earn/web-modal.webp'
							: '/img/earn/cmc-modal.webp'
					}
				/>
				<h3>{title}</h3>
				<p>
					{type === 0
						? 'Check our Telegram channel to stay informed about the latest news and updates.'
						: type === 1
						? 'Follow us on X for the latest updates and information.'
						: type === 2
						? ' Visit our website to learn more about us. '
						: 'Explore our CMC to stay updated on news and upcoming events.'}
				</p>
				<Button
					variant='primary'
					href={link}
					target='_blank'
					onClick={() => {
						setCanCheck(true);
					}}
				>
					{type === 2 ? 'Visit Website ' : `Join ${type === 0 ? 'Telegram' : type === 1 ? 'X' : type === 2 ? 'Website' : 'CMC'}`}
				</Button>
				<div className='reward_container'>
					<img src='/img/dollar.webp' alt='' className='dollar_img' draggable={false} />
					<div className='value'>+{numberUtils.formatPriceWithDecimals(reward)}</div>
				</div>

				<Button className='btn' variant='primary' onClick={onCheckClickHandler} isLoading={isLoading} disabled={!canCheck}>
					Check
				</Button>
			</SocialModalContainer>
		</Modal>
	);
};

export default SocialModal;
