import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EarnContainer = styled.div(
	() => css`
		padding: 24px 24px 120px;
		background: linear-gradient(180deg, #357FAB 15%, rgba(127,178,211,1) 36%, rgba(34,113,161,1) 100%);

	 
	`,
);
