import { FC } from 'react';
import { LotteryListContainer } from '../css';

const LotteryList: FC<{ codes: string[] }> = ({ codes }) => {
	return (
		<LotteryListContainer>
			{codes.map((number, index) => (
				<div key={index} className='card_border'>
					<div className={index % 2 === 0 ? 'card even' : 'card odd'}>
						<div className='circle_border'>
							<div className='circle'>{index + 1}</div>
						</div>
						<div className='lottery_number'>
							<p>Lottery Number</p>
							<div className='number_container'>
								<div className='number_item'>{number[0]}</div>
								<div className='number_item'>{number[1]}</div>
								<div className='number_item'>{number[2]}</div>
								<div className='number_item'>{number[3]}</div>
								<div className='number_item'>{number[4]}</div>
								<div className='number_item'>{number[5]}</div>
								<div className='number_item'>{number[6]}</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</LotteryListContainer>
	);
};

export default LotteryList;
