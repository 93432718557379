import styled from '@emotion/styled';

export const SecretCodeContainer = styled.div`
	color: white;
	
	header {
		height: 75px;
		padding: 0 0.5rem;
		position: relative;

		img {
			position: absolute;
			top: -120px;
			left: 50%;
			height: auto;
			max-width: 300px;
			transform: translateX(-50%);
		}
		.close_button {
			position: absolute;
			top: 25px;
			right: 25px;
		}
	}

	form {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		padding: 1rem 3.25rem;

		.title {
			font-size: 1.5rem;
		}

		.description {
			font-size: 0.875rem;
		}

		input.secret_code_input {
			padding: 1rem;
			height: 3rem;
			border-radius: 0.5rem;
			background: #7bc6f8;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

			&::placeholder {
				color: #0b5b8f;
			}
		}

		button {
			border-radius: 1.5rem;
			border: 1px solid transparent;
		}
	}
`;
