import { useState, useEffect } from 'react';

interface UseChangingProgressProps {
	total: number;
	values: number[];
	interval?: number;
}

const useChangingProgress = ({ values, interval = 60000 }: UseChangingProgressProps) => {
	const [currentValue, setCurrentValue] = useState(values.length > 0 ? (values[values.length - 1])*1.666666667 : 0);

	useEffect(() => {
		if (values.length > 0) {
			setCurrentValue((values[values[values.length - 1]])*1.666666667);
		}
	}, [values]);
	useEffect(() => {
		const id = setInterval(() => {
			setCurrentValue(prevValue => {
				if (prevValue > 0) {
					return prevValue - 1;
				} else {
					clearInterval(id);
					return 0;
				}
			});
		}, interval);

		return () => clearInterval(id);
	}, [interval, values]);

	return currentValue;
};

export default useChangingProgress;
