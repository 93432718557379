import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonContainer = styled.button(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		appearance: none;
		border: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
		border-radius: 16px;
		border: 1.4px solid #9cefebb2;
		padding: 16px 8px 13px;
		&.disabled {
			opacity: 0.7;
		}

		&.primary {
			background: linear-gradient(272.53deg, #902bf5 14.3%, #03dcce 85.45%);
			
		}

		&.outline {
			background-color: transparent;
			border: 1px solid #284c69;
			padding: 16px 8px 13px;
		}
	`,
);

export const LinkContainer = styled.a(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		border-radius: 16px;
		border: 1.4px solid #9cefebb2;
		padding: 12.5px 39px;
		appearance: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.disabled {
			opacity: 0.6;
		}

		&.primary {
			background: linear-gradient(272.53deg, #902bf5 14.3%, #03dcce 85.45%);
 		}

		&.outline {
			background-color: transparent;
			border: 1px solid #284c69;
			padding: 11px 8px 7px;
		}
	`,
);
