import { GETReferralBalance, IReferralBalance } from 'api/v1/referral-balance';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'shared/components/loader';
import { Header, IncomeList } from './components';
import { RewardsHistoryContainer } from './css';

const RewardHistory = () => {
	const [data, setData] = useState<null | IReferralBalance[]>(null);

	useEffect(() => {
		GETReferralBalance().then(res => {
			setData(res);
		});
	}, []);

	return (
		<RewardsHistoryContainer>
			<Helmet>
				<title>PinEye | reward history</title>
			</Helmet>
			<div className='top_light'></div>
			<Header />
			{data ? <IncomeList data={data} /> : <Loader />}
		</RewardsHistoryContainer>
	);
};

export default RewardHistory;
