import { createSlice } from '@reduxjs/toolkit';
import { IProfileResponse } from 'api/v2/profile';
import { IProfile } from 'store/types';

const initialState: IProfile = {
	isLogin: localStorage.getItem('access_auth_token') !== null ? true : false,
	earnPerTap: 1,
	friendsCount: 0,
	level: null,
	avatar: null,
	name: null,
	balance: 0,
	tapCount: 0,
	replay: false,
	walkthrough: localStorage.getItem('walkthrough') ? (localStorage.getItem('walkthrough') === 'true' ? true : false) : false,
	energy: null,
	notif: [],
};

export const Profile = createSlice({
	name: 'profile',
	initialState: initialState,
	reducers: {
		changeProfileLogin: (state, action: { payload: { isLogin: boolean; avatar: null | string; name: string } }) => {
			state.avatar = action.payload.avatar;
			state.isLogin = action.payload.isLogin;
			state.name = action.payload.name;
		},
		changeProfileOverview: (state, action: { payload: IProfileResponse }) => {
			state.earnPerTap = action.payload.profile.earnPerTap;
			state.friendsCount = action.payload.profile.friendsCount;
			state.level = action.payload.profile.level;
			state.balance = action.payload.profile.totalBalance;
			state.energy = action.payload.energy;
			state.notif = action.payload.notifs;
		},

		changeBalance: (state, action) => {
			state.balance = action.payload.balance;
			if (action.payload.level && action.payload.level.isLevelUped) {
				state.level = action.payload.level.toLevel;
			}
			// state.energy = action.payload.energy;
		},

		changeTap: (state, action) => {
			if (action.payload !== 0) {
				state.tapCount += 1;
				state.balance += state.earnPerTap;
				if (state.energy && state.energy.currentEnergy > state.earnPerTap) {
					state.energy.currentEnergy -= state.earnPerTap;
				}
			} else {
				state.tapCount = 0;
			}

			document.dispatchEvent(new CustomEvent('tap-count', { detail: state.tapCount }));
		},

		changeEnergy: (state, action) => {
			if (state.energy && action.payload.type === 'add') {
				if (state.energy.currentEnergy < state.energy.maxEnergy) {
					if (state.energy.currentEnergy + state.energy.energyIncreasePerSecond > state.energy.maxEnergy) {
						state.energy.currentEnergy = state.energy.maxEnergy;
					} else {
						state.energy.currentEnergy += state.energy.energyIncreasePerSecond;
					}
				}
			}
		},

		resetEnergy: state => {
			if (state.energy) {
				state.energy.currentEnergy = state.energy.maxEnergy;
			}
		},

		changeReplay: state => {
			state.replay = !state.replay;
		},

		changeNotifs: (state, action) => {
			state.notif = action.payload;
		},

		changeWalkthrough: state => {
			state.walkthrough = true;
			localStorage.setItem('walkthrough', 'true');
		},
	},
});

export const {
	changeProfileLogin,
	changeProfileOverview,
	changeBalance,
	changeTap,
	changeReplay,
	changeNotifs,
	changeWalkthrough,
	changeEnergy,
	resetEnergy,
} = Profile.actions;
export default Profile.reducer;
