import { HeaderContainer } from '../css';

const Header = () => {
	return (
		<HeaderContainer>
			<h1>Extra reward history</h1>
			<p>Invite friends to join and get 30% extra coins based on their earnings every day!</p>
			<div className='note'>
				<strong>Note :</strong> Only the latest 10 records from the last month are displayed.
			</div>
		</HeaderContainer>
	);
};
export default Header;
