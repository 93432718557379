import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ExchangeContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		background: linear-gradient(180deg, #2574a4 20%, rgba(127, 178, 211, 1) 45%, #2271a1 100%);
		padding: 44px 24px 170px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		h1 {
			text-align: center;
			margin-bottom: 22px;
		}
		.green {
			color: #35d899;
		}
		.border {
			border-radius: 12px;
			padding: 1px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			.list_item {
				background: linear-gradient(76.45deg, #0979bd 50.77%, #89bbda 105.62%);
				border-radius: 11px;
				padding: 12px 32px 12px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
                .arrow{
                    margin-top: 5px;
                }
				.content {
					display: flex;
					gap: 22px;
					align-items: center;
					.icon {
						width: 72px;
						height: 62px;
					}
					.text {
						font-size: 18px;
						font-weight: 500;
						line-height: 22px;
						margin-top: 3px;
					}
				}
			}
		}
	`,
);
