import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LotteryListContainer = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		.card_border {
			padding: 1px;
			background: linear-gradient(189deg, rgba(255, 255, 255, 0.09) 30.3%, rgba(35, 135, 197, 0.27) 67.25%);
			border-radius: 9px;
			box-shadow: 0px 15px 7px 0px #ffffffb8 inset;
			margin-top: -8px;

			.card {
				display: grid;
				grid-template-columns: 51px auto;
				grid-auto-flow: column;
				padding: 24px 16px 30px;
				border-radius: 8px;
				align-items: center;
				justify-items: center;
				gap: 7px;
				&.even {
					background: linear-gradient(192.28deg, #1c6fac -14.57%, rgba(50, 142, 229, 0) 117.26%);
				}
				&.odd {
					background: linear-gradient(
						180deg,
						rgba(106, 98, 225, 0.8) 30%,
						rgba(73, 119, 210, 1) 65%,
						rgba(38, 133, 200, 0.37) 100%
					);
				}
				.circle_border {
					padding: 1px;
					background: linear-gradient(180deg, rgba(153, 153, 153, 0) 39%, rgba(255, 255, 255, 0.57) 100%);
					width: 51px;
					height: 51px;
					border-radius: 50%;
					margin-bottom: -10px;
					.circle {
						background: #4c51cf;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						font-size: 19px;
						font-weight: 400;
						line-height: 23.5px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.lottery_number {
					display: flex;
					flex-direction: column;
					gap: 4px;
					p {
						font-size: 14px;
						font-weight: 400;
						line-height: 16.8px;
						text-align: left;
						margin: 0;
						/* margin-bottom: 4px; */
					}
					.number_container {
						display: flex;
						gap: 4px;
						/* margin-bottom: 26px; */
						justify-content: center;
						.number_item {
							background: #ffffff52;
							width: 31px;
							height: 31px;
							border-radius: 4px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-weight: 600;
							font-size: 16px;
							padding-top: 4px;
						}
					}
				}
			}
		}
		@media (max-width: 370px) {
			.card_border {
				.card {
					.lottery_number {
						.number_container {
							.number_item {
								width: 23px;
								height: 23px;
							}
						}
					}
				}
			}
		}
		@media (max-width: 270px) {
			.card_border {
				.card {
					.lottery_number {
						.number_container {
							.number_item {
								width: 17px;
								height: 17px;
							}
						}
					}
				}
			}
		}
	`,
);
