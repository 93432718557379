import requestHandler from 'shared/utils/request-handler';
import { IProfileResponse } from './profile.dto';

const BASE_URL = '/api/v1/profile';

export const GETProfileAPI = async () => {
	return requestHandler.call<IProfileResponse>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};
export const POSTBuyBoosterPlan = async (id: number) => {
	return requestHandler.call<string>({ url: `${BASE_URL}/BuyBoostPlan?boostPlanId=${id}`, method: 'post' }).then(res => res.data);
};
export const POSTBuyBooster = async (id: number) => {
	return requestHandler.call<string>({ url: `${BASE_URL}/BuyBooster?boosterId=${id}`, method: 'post' }).then(res => res.data);
};

export const POSTMassageNotified = async () => {
	return requestHandler.call<{ isDone: boolean }>({ url: `${BASE_URL}/WelcomeMessageNotifed`, method: 'post' }).then(res => res.data);
};
export const POSTReferralDailyNotified = async () => {
	return requestHandler
		.call<{ isDone: boolean }>({ url: `${BASE_URL}/ReferralDailyRewardNotifed`, method: 'post' })
		.then(res => res.data);
};
