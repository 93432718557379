import requestHandler from 'shared/utils/request-handler';
import { ILotteryResponse } from './lottery.dto';

const BASE_URL = '/api/v2/Lottery';

export const GETLotteryAPI = async () => {
	return requestHandler.call<ILotteryResponse>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const POSTBuyTicketAPI = async (count: number) => {
	return requestHandler
		.call<{ codes: string[]; balance: number }>({ url: `${BASE_URL}/BuyTicket?count=${count}`, method: 'post' })
		.then(res => res.data);
};
