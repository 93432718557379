import { IReferralBalance } from 'api/v1/referral-balance';
import moment from 'moment';
import { FC } from 'react';
import { UnhappyOutline } from 'shared/components/icons';
import numberUtils from 'shared/utils/number';
import { FriendsListContainer } from '../css';

const IncomeList: FC<{ data: IReferralBalance[] }> = ({ data }) => {
	return (
		<FriendsListContainer>
			{data.length === 0 && (
				<div className='empty_container'>
					<p>No record found!</p>
					<UnhappyOutline />
				</div>
			)}

			{data.length > 0 && (
				<div className='list_container'>
					{data.map((item, index) => (
						<div className='list_item' key={`list_item_${index}`}>
							<div className='list_item_inner'>
								<div className='title'>
									<span>{item.userName}</span>
									{moment(item.dateTimeStamp * 1000).format('YYYY/MM/DD')}

								</div>
								<img src='/img/dollar.webp' width={20} height={20} alt='' />
								<div className='value'>
									<p>{numberUtils.formatPriceWithDecimals(item.balance)}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</FriendsListContainer>
	);
};

export default IncomeList;
