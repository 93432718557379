import requestHandler from 'shared/utils/request-handler';
import { IGetAllResponse, IConnectExchangeResponse } from './exchange.dto';

const BASE_URL = '/api/v1/Exchange';

export const GetExchange = async () => {
	const url = `${BASE_URL}/Get`;
	return requestHandler
		.call<string>({
			url,
			method: 'get',
		})
		.then(res => res.data);
};

export const GETAllExchange = async () => {
	const url = `${BASE_URL}/GetAll`;
	return requestHandler
		.call<IGetAllResponse[]>({
			url,
			method: 'get',
		})
		.then(res => res.data);
};

export const POSTConnectExchange = async (exchange: string) => {
	const url = `${BASE_URL}/ConnectExchange`;
	return requestHandler
		.call<IConnectExchangeResponse>({
			url,
			method: 'post',
			data: {
				exchange: exchange,
			},
		})
		.then(res => res.data);
};
