import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BuyModalContainer = styled.div(
	() => css`
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: flex-end;
		z-index: 3;
		.border {
			padding: 2px 2px 0 2px;
			height: 50vh;
			background: linear-gradient(183.27deg, rgba(255, 255, 255, 0.91) -10.22%, rgba(130, 133, 234, 0) 21.23%);
			border-radius: 22px 22px 0 0;
			box-shadow: 0px 9px 7px 0px #ffffffa1 inset;
			width: 100%;
			.modal_content {
				background: linear-gradient(180deg, #0c5c8f 68.92%, #031a29 106.65%);
				border-radius: 20px 20px 0 0;
				padding: 20px;
				width: 100%;
				height: 50vh;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				position: relative;
				.close_icon {
					align-self: flex-end;
					width: 20px;
					height: 20px;
				}
				h2 {
					text-align: center;
					font-size: 25px;
					font-weight: 600;
					line-height: 15px;
					z-index: 3;
					margin-top: 65px;
					position: relative;
				}
				.img_container {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					position: absolute;
					top: -70px;
					left: 0;
					right: 0;
					margin: auto;
					img {
						width: 73%;
					 }
				}
				.text {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: justify;
					margin-bottom: 18px;
					color: #ffffff;
					padding: 0 24px;
				}
				.number_container {
					display: flex;
					gap: 6px;
					margin-bottom: 26px;
					justify-content: center;
					.number_item {
						background: #ffffff52;
						width: 39px;
						height: 39px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-weight: 600;
						font-size: 16px;
						padding-top: 4px;
					}
				}
				button {
					margin-bottom: 30px;
 					width: 100%;
 					 
					img {
						width: 20px;
						height: 20px;
						margin: 0;
					}
				}
			}
		}

		@media (max-width: 390px) {
			.border {
				height: 55vh;
				.modal_content {
					height: 55vh;
					padding: 10px;
					.img_container {
						top: -60px;
					}
					.text {
						font-size: 12px;
					}
					.number_container {
						.number_item {
							width: 25px;
							height: 25px;
						}
					}
					button {
						font-size: 14px;
						height: 48px;
						img {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
		}
	`,
);
