import requestHandler from 'shared/utils/request-handler';
import { IClaimDailyRewardResponse, IDailyRewardResponse } from './daily-reward.dto';

const BASE_URL = '/api/v1/DailyReward';

export const GETDailyRewards = async () => {
	return requestHandler.call<IDailyRewardResponse>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};
export const POSTClaimDailyRewards = async () => {
	return requestHandler.call<IClaimDailyRewardResponse>({ url: `${BASE_URL}/claim`, method: 'post' }).then(res => res.data);
};
