import { useEffect, useState } from 'react';
import { WalletBalanceContainer } from '../css';
import { GETGetAllTokenBalance, IGetAllTokenBalanceResponse } from 'api/v1/token-balance';
import Loader from 'shared/components/loader';
const userBalanceList = [
	{
		tokenTitle: 'pineye',
		icon: <img src={'/img/coin/pineye.webp'} width={66} height={66} />,
		value: 0,
	},
	{
		tokenTitle: 'usdt',
		icon: <img src={'/img/coin/usdt.webp'} width={72} height={72} />,
		value: 0,
	},
	{
		tokenTitle: 'not',
		icon: <img src={'/img/coin/not.webp'} width={66} height={66} />,
	},
];

const BalanceList = () => {
	const [data, setData] = useState<IGetAllTokenBalanceResponse[] | null>(null);

	useEffect(() => {
		GETGetAllTokenBalance().then(res => {
			const requiredTokens = ['not', 'usdt', 'pineye'];
			const updatedData = [...res];

			requiredTokens.forEach(token => {
				if (!updatedData.some(item => item.tokenTitle.toLowerCase() === token)) {
					updatedData.push({
						tokenTitle: token.toUpperCase(),
						tokenBalance: 0,
					});
				}
			});
			setData(updatedData);
		});
	}, []);

	const getIcon = (tokenTitle: string) => {
		const token = userBalanceList.find(item => item.tokenTitle === tokenTitle.toLowerCase());
		return token ? token.icon : null;
	};

	return (
		<WalletBalanceContainer>
			<b className={'title'}>Your Balance</b>

			<div className='wallet_container'>
				{!data && <Loader />}

				{data &&
					data.map(balance => (
						<div key={balance.tokenTitle} className={`wallet ${balance.tokenTitle.toLowerCase()}`}>
							{getIcon(balance.tokenTitle)}
							<div className='detail'>
								<p>{balance.tokenTitle.toLowerCase()}</p>
								<span className='tokenBalance'>
									<div>{balance.tokenBalance}</div>
								</span>
							</div>
						</div>
					))}
			</div>
		</WalletBalanceContainer>
	);
};

export default BalanceList;
