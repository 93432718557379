import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
	({ theme }) => css`
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		margin-bottom: 32px;

		h3 {
			color: ${theme.colors.text.primary};
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			margin: 0;
		}

		.balance_container {
			color: ${theme.colors.text.primary};
			display: flex;
			align-items: flex-start;
			gap: 6px;

			font-size: 34px;
			font-weight: 600;
			line-height: 42px;

			img {
				width: 36px;
			}
		}
		.how {
			background: linear-gradient(274.5deg, #03dcce 11.65%, #3893cb 57.17%);
			box-shadow: 0px 4px 4px 0px #ffffffa1 inset;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			padding: 12px 22px 8px;
			border-radius: 86px;
			color: #fff;
			a {
				text-decoration: none;
				color: #fff;
			}
		}
	`,
);
