import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HighlightContainer = styled.div(
	() => css`
		margin-top: 115px;
		position: relative;
		z-index: 2;
		background: linear-gradient(76.45deg, #0979BD 50.77%, #89BBDA 105.62%);
		box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
		border: 1px solid transparent;
		border-image: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
		background-origin: border-box;
		background-clip: padding-box, border-box;
		border-radius: 12px;
		padding: 10px 0 8px 24px;
		h2 {
			color: white;
			font-weight: 500;
			text-align: left;
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 4px;
		}
		.timeline_container {
			display: grid;
			grid-template-columns: 27px 1fr;
			grid-template-rows: auto 1fr;
			position: relative;
			align-items: start;
			img {
				width: 27px;
				height: 27px;
				display: flex;
				align-items: center;
				justify-content: center;
				grid-row: 1;
			}
		}
		.line {
			grid-column: 1;
			grid-row: 2;
			background: repeating-linear-gradient(180deg, white 11px, white 17px, transparent 22px, transparent 8px);
			width: 2px;
			height: 100%;
			margin: 0 auto;
		}
		.item {
			grid-column: 2;
			grid-row: 1 / span 2;
 			width: 100%;
			display: flex;
			align-items: center;
 			font-size: 14px;
			font-weight: 400;
			div {
				display: flex;
				flex-direction: column;
				color: #fff;
				border-radius: 18.5px;
				padding: 8px;
				width: 100%;
				font-size: 12.5px;
				font-weight: 500;
				line-height: 15.16px;
				text-align: left;
			}
			.odd {
				background: linear-gradient(
					89.28deg,
					rgba(13, 125, 194, 0.53) 10.6%,
					rgba(66, 179, 249, 0.43) 35.85%,
					rgba(53, 124, 168, 0) 143.41%
				);
			}
			.even {
				background: linear-gradient(
					189.28deg,
					rgba(13, 125, 194, 0.53) 10.6%,
					rgba(66, 179, 249, 0.43) 35.85%,
					rgba(53, 124, 168, 0) 143.41%
				);
			}
		}
	`,
);
