import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalletContainer = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: 1rem;
		padding: 24px 24px 110px;
		position: relative;
		background: linear-gradient(180deg, rgba(132, 182, 215, 1) 0%, rgba(127, 178, 211, 1) 36%, rgba(34, 113, 161, 1) 100%);
		h1 {
			color: white;
			font-size: 20px;
			font-weight: 400;
			line-height: 25px;
			text-align: center;
			position: relative;
			z-index: 1;
		}
		.campaign_banner {
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			img {
				height: auto;
				width: 100%;
			}
		}
		#ton-connect-button {
			width: 92% !important;
			position: fixed;
			bottom: 120px;
			z-index: 2;
			left: 0;
			right: 0;
			margin: auto;
			button {
				border: 1.4px solid #9cefebb2;
				width: 100%;
				background: linear-gradient(272.78deg, #902bf5 14.14%, #03dcce 91.66%);
				height: 56px;
				justify-content: center;
				border-radius: 16px;
			}
			div {
				width: unset;
			}
		}
		button.connect_wallet {
			display: flex;
			justify-content: center;
			column-gap: 0.25rem;
			border-radius: 1.5rem;
			fill: #ffffff;
		}
	`,
);
