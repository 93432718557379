import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SpecialContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};

		.special_title {
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 12px;
		}

		.special_card {
			background: linear-gradient(5.66deg, #0979bd 43.72%, #89bbda 119.59%);
			border-top: 1.5px solid #ffffff89;
			border-right: 1.5px solid #ffffff89;
			box-shadow: 0px 4px 4px 0px #ffffff8f inset;

			border-radius: 12px;
			padding: 12px 17px;
			display: grid;
			grid-template-columns: 44px 1fr 24px;
			grid-template-rows: 1fr 1fr;
			gap: 5px 14px;
			margin-bottom: 12px;

			.img_container {
				grid-row: 1/3;
				width: 44px;
				height: 44px;
				background: transparent;
				display: flex;
				align-items: center;
				justify-content: center;

				svg,
				img {
					width: 44px;
					height: 44px;
				}
			}

			.title {
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				margin: auto auto 0 0;
			}
			.value {
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: ${theme.colors.text.primary};
				display: flex;
				align-items: flex-start;
				gap: 5px;
				grid-row: 2;
				margin: 0 auto auto 0;

				&.green {
					color: #28f39e;
				}

				img {
					width: 14px;
				}
			}

			svg {
				grid-row: 1/3;
				margin: auto;

				&.green {
					color: #28f39e;
				}
			}
		}

		.other_container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 12px;
			.special_card_other {
				border: 1.5px solid #ffffff;
				box-shadow: 0px 4px 4px 0px #ffffff8f inset;
				background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff 0%, #0873b4 90.5%, #0873b4 100%);
				gap: 10px;
				border-radius: 12px;
				padding: 16px 14px;
				display: flex;
				flex-direction: column;

				.img_container {
					display: flex;
					align-items: center;
					justify-content: center;

					svg,
					img {
						width: 65px;
						height: 65px;
					}
				}

				.title {
					font-size: 14px;
					font-weight: 500;
					line-height: 17px;
					text-align: center;
				}
				.value {
					padding: 2px;
					background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
					border-radius: 27px;

					.border {
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						color: ${theme.colors.text.primary};
						display: flex;
						align-items: flex-end;
						gap: 3px;
						justify-content: center;
						/* margin: 0 auto auto 0; */
						background: #0873b4;
						border-radius: 24px;
						height: 30px;
						&.green {
							color: #28f39e;
						}
						.green {
							color: #28f39e;
							width: 14px;
							margin-bottom: -2px;
						}
						img {
							width: 14px;
							margin-bottom: 3px;
						}
					}
				}
			}
		}

		.other_tasks {
			margin-top: 24px;
		}
	`,
);
