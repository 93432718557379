import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DotLoaderContainer = styled.div(
	() => css`
		height: 17px;
		border-radius: 100%;
		position: relative;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		display: flex;

		div {
			display: inline-flex;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: #fff;
			margin: 0 2px;
			animation: anm-bl-3-move 1.3s infinite ease-in-out both;

			&:nth-of-type(1) {
				animation-delay: -320ms;
			}
			:nth-of-type(2) {
				animation-delay: -160ms;
			}
		}

		@keyframes anm-bl-3-move {
			0%,
			80%,
			100% {
				transform: scale(0);
			}

			40% {
				transform: scale(1);
			}
		}
	`,
);
