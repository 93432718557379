import { Dispatch, FC, SetStateAction } from 'react';
import { TabContainer } from '../css';

const Tab: FC<{ activeTab: 'SPECIAL' | 'REFERRAL'; setActiveTab: Dispatch<SetStateAction<'SPECIAL' | 'REFERRAL'>> }> = ({
	activeTab,
	setActiveTab,
}) => {
	return (
		<TabContainer>
			<div className='border'>
			<div
				className={`tab_item ${activeTab === 'SPECIAL' ? 'active' : ''}`}
				onClick={() => {
					if (activeTab !== 'SPECIAL') {
						setActiveTab('SPECIAL');
					}
				}}
			>
				Special
			</div>
			<div
				className={`tab_item ${activeTab === 'REFERRAL' ? 'active' : ''}`}
				onClick={() => {
					if (activeTab !== 'REFERRAL') {
						setActiveTab('REFERRAL');
					}
				}}
			>
				Referral
			</div>
			</div>
		</TabContainer>
	);
};

export default Tab;
