import { GETSocial, ISocialResponse } from 'api/v1/social';
import { useEffect, useState } from 'react';
import { ArrowRightOutline, TickBold } from 'shared/components/icons';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { SpecialContainer } from '../css';
import SocialModal from './social-modal';
import DailyModal from './daily-modal';
import { GETDailyRewards, IDailyRewardResponse } from 'api/v1/daily-reward';
import { useAppSelector } from 'shared/hooks/redux-helper';
import Icon from 'shared/components/icon';

const Special = () => {
	const [socialData, setSocialData] = useState<ISocialResponse[] | null>(null);
	const [dailyData, setDailyData] = useState<IDailyRewardResponse | null>(null);

	const replay = useAppSelector(state => state.profile.replay);

	const [socialModalStatus, setSocialModalStatus] = useState<{
		status: boolean;
		id: number;
		reward: number;
		title: string;
		link: string;
		type: number;
	}>({
		id: 0,
		link: '',
		reward: 0,
		type: 0,
		title: '',
		status: false,
	});
	const [dailyModalStatus, setDailyModalStatus] = useState<{
		status: boolean;
	}>({
		status: false,
	});

	useEffect(() => {
		setSocialData(null);
		setDailyData(null);
		GETSocial().then(res => {
			setSocialData(res);
		});

		GETDailyRewards().then(res => {
			setDailyData(res);
		});
	}, [replay]);

	return (
		<SpecialContainer>
			{socialData && dailyData ? (
				<>
					<div className='special_title'> Daily Reward</div>
					<div
						className='special_card'
						onClick={() => {
							setDailyModalStatus({ status: true });
						}}
					>
						<div className='img_container'>
							<Icon name={'calendar'} width={44} height={44} />
						</div>
						<div className='title'>Daily reward</div>
						<div className='value'>
							<img src='/img/dollar.webp' alt='' />
							1,000,000
						</div>
						{dailyData.canClaim ? <ArrowRightOutline /> : <TickBold className='green' />}
					</div>

					<div className='other_tasks'>
						<div className='special_title'>Daily Tasks</div>

						<div className='other_container'>
							{socialData.map((item, index) => (
								<div
									key={`social_item_${index}`}
									className='special_card_other'
									onClick={() => {
										if (!item.isClaimed) {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												type: item.type,
											});
										}
									}}
								>
									<div className='img_container'>
										{item.type === 0 && <Icon name='telegram_flat' width={65} height={65} />}
										{item.type === 1 && <Icon name='x_flat' width={65} height={65} />}
										{item.type === 2 && <Icon name='web_flat' width={65} height={65} />}
										{item.type === 3 && <Icon name='cmc_flat' width={65} height={65} />}
									</div>
									<div className='title'>{item.title}</div>
									<div className={`value ${item.isClaimed ? 'green' : ''}`}>
										<div className='border'>
											<img src='/img/dollar.webp' alt='' />+
											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
											{item.isClaimed ? <TickBold className='green' /> : ''}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			) : (
				<Loader />
			)}

			{socialModalStatus.status && (
				<SocialModal
					id={socialModalStatus.id}
					link={socialModalStatus.link}
					reward={socialModalStatus.reward}
					status={socialModalStatus.status}
					type={socialModalStatus.type}
					setStatus={status => {
						setSocialModalStatus({ ...socialModalStatus, status: status });
					}}
					title={socialModalStatus.title}
				/>
			)}
			{dailyModalStatus.status && dailyData && (
				<DailyModal
					status={dailyModalStatus.status}
					setStatus={status => {
						setDailyModalStatus({ ...dailyModalStatus, status: status });
					}}
					data={dailyData}
					title={'Daily Reward'}
				/>
			)}
		</SpecialContainer>
	);
};

export default Special;
