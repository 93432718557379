import { FC } from 'react';
import Modal from 'shared/components/modal';
import { useAppSelector } from 'shared/hooks/redux-helper';
import useCloseModal from 'shared/hooks/use-close-modal';
import { LotteryModalContainer } from '../css';

const LotteryModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const notifs = useAppSelector(state => state.profile.notif);

	const lotteryMetaData = JSON.parse(notifs.find(item => item.type === 'LotteryReward')!.metatData);

	const onCloseClickHandler = () => {
		onClose();
	};
	return (
		<Modal
			isBottomSheet
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			disableHeader={true}
			backgroundColor='linear-gradient(180deg, #0B3079 0%, #3681D0 100%)'
		>
			<LotteryModalContainer>
				<img src='img/lottery_modal_balls.webp' alt='' className='header_img' />
				<div className='prize_container'>
					<div className='title'>Prize Pool</div>
					<img src='img/lottery_modal_prize_number.webp' alt='' className='prize' />
				</div>

				<div className='winner_title'>Today Lucky Winner</div>

				<div className='numbers_container'>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[0]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[1]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[2]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[3]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[4]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[5]}</div>
					</div>
					<div className='number_item'>
						<div className='number'>{lotteryMetaData.Code[6]}</div>
					</div>
				</div>

				<div className='divider'></div>

				<div className='description'>
					Who knows?
					<br />
					You could be our next big winner!
				</div>
			</LotteryModalContainer>
		</Modal>
	);
};

export default LotteryModal;
